/**
 * The Great Flattener
 */
import { Flattener } from "./src/flattener";
/**
 * Flatten an object
 * @param obj - the object to flatten
 * @returns - results
 */
export function flatten(obj) {
  const flattener = new Flattener(obj);
  return flattener.flatten();
}