import { Pipe, PipeTransform } from '@angular/core';
import { Dayjs } from 'dayjs';
import { ISO8601Date } from '../cgt-view/dividend-calculator/-services-/models/iso8601-date.model';
import { renderFancyDateTime } from '../utilities/formatting-helpers';

@Pipe({
  name: 'appDateTime'
})
export class AppDateTimePipe implements PipeTransform {
  transform(input?: Date | Dayjs | ISO8601Date): string {
    return input ? renderFancyDateTime(input) : '';
  }
}
