// Singleton class for storing JWT as a string.

import { Account } from '../../../account-mgmt/account.model';
import { Roles } from '../api-constants/roles/roles';
import { TokenPersistenceLayer } from '../../../../api-connector/token-persistence-layer';
import { JWT_KEY, ACCOUNT_KEY } from '../auth-provider.constants';

type JWT = string;
const ESTM_HOST_SELECTOR_USE_LOCALHOST = 'ESTM_HOST_SELECTOR_USE_LOCALHOST';

class AccountPersistenceLayerImpl {
  private cachedAccount: Account | undefined;
  private cachedJWT: JWT | undefined;

  constructor() {
    this.cachedAccount =
      AccountPersistenceLayerImpl.loadAccountFromLocalStorage();
    this.cachedJWT = AccountPersistenceLayerImpl.loadJwtFromLocalStorage();
  }

  get account(): Account {
    this.assertIsTruthy(this.cachedAccount);
    return this.cachedAccount;
  }

  get jwt(): JWT | null {
    return TokenPersistenceLayer.getToken();
  }

  set account(acc) {
    localStorage.setItem(ACCOUNT_KEY, JSON.stringify(acc));
    this.cachedAccount = acc;
  }

  set jwt(newJwt) {
    TokenPersistenceLayer.setToken(newJwt);
  }

  // Returns whether the persistence layer has both jwt and account
  hasValues(): boolean {
    return this.hasJWT() && this.hasAccount();
  }

  hasJWT(): boolean {
    return this.jwt !== null;
  }

  hasAccount(): boolean {
    try {
      this.assertIsTruthy(this.cachedAccount);
      return true;
    } catch (expected) {
      return false;
    }
  }

  clear(): void {
    this.cachedAccount = undefined;
    this.cachedJWT = undefined;
    localStorage.removeItem(ACCOUNT_KEY);
    localStorage.removeItem(JWT_KEY);
    localStorage.removeItem(ESTM_HOST_SELECTOR_USE_LOCALHOST);
  }

  // ------------------------

  /**
   * Asserts a value must be truthy, or throw an error
   * @private
   */
  private assertIsTruthy: <T>(prop: T) => asserts prop is NonNullable<T> = <T>(
    prop: T
  ): asserts prop is NonNullable<T> => {
    if (!prop) {
      throw new Error(`Can't get the requested prop`);
    }
  };

  private static loadAccountFromLocalStorage(): Account | undefined {
    const rawValue: string | null = localStorage.getItem(ACCOUNT_KEY);
    if (!rawValue) {
      return undefined;
    } else {
      const rawAccount = JSON.parse(rawValue) as Account;

      // need to load account constants from imports to avoid duplication of role class
      rawAccount.roles = rawAccount.roles.map((role) =>
        Roles.byApiInteger(role.apiInteger)
      );

      return rawAccount;
    }
  }

  private static loadJwtFromLocalStorage(): JWT | undefined {
    return localStorage.getItem(JWT_KEY) ?? undefined;
  }
}

export const AccountPersistenceLayer = new AccountPersistenceLayerImpl();
