<span
  class="p-float-label"
  [class.p-input-icon-left]="effectiveIconPosition() == 'left'"
  [class.p-input-icon-right]="effectiveIconPosition() == 'right'"
  [class.p-fluid]="fluid"
>
  <i *ngIf="shouldHaveIcon()" [class]="icon"></i>
  <ng-content></ng-content>
  <label><span *ngIf="isMandatory" style="color: red; font-size: larger">* </span>{{ label }}</label>
</span>
