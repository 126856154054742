import { NgModule } from '@angular/core';

import { AppAutoFocusDirective } from './util/app-auto-focus/app-auto-focus.directive';
import { IdleTimeoutDirective } from './util/idle-timeout.directive';

@NgModule({
  declarations: [AppAutoFocusDirective, IdleTimeoutDirective],
  exports: [AppAutoFocusDirective, IdleTimeoutDirective],
  imports: []
})
export class CustomDirectivesModule {}
