import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-and-loading-notice-cards',
  templateUrl: './error-and-loading-notice-cards.component.html',
  styleUrls: ['./error-and-loading-notice-cards.component.css']
})
export class ErrorAndLoadingNoticeCardsComponent {

  @Input() isLoading!: boolean;
  @Input() errorText!: string | null;

}
