import dayjs, { Dayjs } from 'dayjs';
import { APP_CA_VIEW_DATE_FORMAT, APP_DATE_FORMAT } from '../app.constants';

/**
 * Render a fancy version of a date time string using dayjs, with a fallback if the date is invalid.
 * This also includes a relative time string (e.g. "3 days ago").
 */
export function renderFancyDateTimeWithDelta(date: Date | string, fallback: string = 'Invalid Date'): string {
  const d = dayjs(date);
  if (!d.isValid()) return fallback;
  return `${d.format('LLL')} (${d.fromNow()})`;
}
/**
 * Render a simple version of a date time string using dayjs, .
 * This also includes a relative time string (e.g. "3 days ago").
 */
export function renderDateTimeWithDelta(date: Date | string, includeTime: boolean = true): string {
  const d = dayjs(date);
  if (!d.isValid()) return '';
  return includeTime ? `${d.format('DD/MM/YYYY - h:mm:ss a')} (${d.fromNow()})` : d.format('DD/MM/YYYY');
}

/**
 * Render a fancy version of a date time string using dayjs, with a fallback if the date is invalid.
 * @param date - The date to be formatted
 * @param fallback - The fallback string to be returned if the date is invalid. Defaults to 'Invalid Date'.
 * @returns The formatted date time string, or the fallback string if the date is invalid.
 */
export function renderFancyDateTime(date: Date | Dayjs | string, fallback: string = 'Invalid Date'): string {
  const d = dayjs(date);
  if (!d.isValid()) return fallback;
  return d.format('LLL');
}

/**
 * Render a fancy version of a date string in DD/MM/YYYY using dayjs,
 * with a fallback if the date is invalid.
 * This does not include the time component or a relative time string.
 */
export function renderDate(date: Date | string, fallback: string = 'Invalid Date'): string {
  const d = dayjs(date);
  if (!d.isValid()) return fallback;
  return d.format(APP_DATE_FORMAT);
}

/**
 * Render a fancy version of a date string in DD-MMM-YYYY using dayjs,
 * with a fallback if the date is invalid.
 * This does not include the time component or a relative time string.
 */
export function renderCADate(date: Date | string, fallback: string = 'Invalid Date'): string {
  const d = dayjs(date);
  if (!d.isValid()) return fallback;
  return d.format(APP_CA_VIEW_DATE_FORMAT);
}

/**
 * Renders a given number as a currency string with the specified currency sign.
 * @param {number} rawValue - The raw value to be formatted as a currency string.
 * @param {string} currency The currency to be used in the formatted string. Defaults to '$'.
 * @param fallbackText The fallback text to be returned if the rawValue is null. Defaults to 'N/A'.
 * @returns {string} - The currency string with the specified sign.
 */
export function renderCurrency(rawValue: number | null, currency: string = 'AUD', fallbackText: string = 'N/A'): string {
  if (rawValue === null) return fallbackText;
  return rawValue.toLocaleString(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}
