// Custom error class for API errors

export class ApiError extends Error {

  constructor(public errorCode: number, public message: string) {
    super();
  }

  toString(): string {
    return `API Error ${this.errorCode}: ${this.message}`
  }

}
