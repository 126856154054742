export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_CA_VIEW_DATE_FORMAT = 'DD-MMM-YYYY';
export const APP_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss a';

// AU TAX YEAR START = 1st July XXXX
export const AU_TAX_YEAR_START_DATE = 1;
export const AU_TAX_YEAR_START_MONTH = 6;
// AU TAX YEAR START = 30th Jun XXXX
export const AU_TAX_YEAR_END_DATE = 30;
export const AU_TAX_YEAR_END_MONTH = 5;

export const AU_TAX_YEAR = {
  START: {
    DATE: AU_TAX_YEAR_START_DATE,
    MONTH: AU_TAX_YEAR_START_MONTH
  },
  END: {
    DATE: AU_TAX_YEAR_END_DATE,
    MONTH: AU_TAX_YEAR_END_MONTH
  }
};

export const ALL_VALUE = 'ALL';

// Temporary change for MVP #CC-841
export const hideSubPortfolio = true;

export const DISCLAIMER_MSG =
  'Please note that the information on this website does not represent legal or taxation advice. While every effort is made to ensure the information is correct, eSTM Pty Ltd does not take responsibility for potential data errors or omissions.';
