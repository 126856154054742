// A plain TS interface for posting data to the API

import { apiConfig } from './api-config';
import { AccountPersistenceLayer } from '../jwt/account-persistence-layer';
import { ApiError } from './api-error';
import { ACCESS_TOKEN_KEY } from '../auth-provider.constants';

export class LegacyDirectApiRequest {
  static async post(path: string, data: any): Promise<any> {
    return await LegacyDirectApiRequest.fetchAndGetJSON('POST', path, data);
  }

  static async get(path: string): Promise<any> {
    return await LegacyDirectApiRequest.fetchAndGetJSON('GET', path, undefined);
  }

  static async put(path: string, data: any): Promise<any> {
    return await LegacyDirectApiRequest.fetchAndGetJSON('PUT', path, data);
  }

  static async delete(path: string, data: any): Promise<any> {
    return await LegacyDirectApiRequest.fetchAndGetJSON('DELETE', path, data);
  }

  static async patch(path: string, data: any): Promise<any> {
    return await LegacyDirectApiRequest.fetchAndGetJSON('PATCH', path, data);
  }

  // Please do not use this to call apis
  /**
   * Sends a request to the API.
   * @param method - HTTP method to use
   * @param path - relative path to push to
   * @param data - any body data
   * @private
   */
  private static async fetchAndGetJSON(
    method: string,
    path: string,
    data: any
  ) {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    const headers: any = {
      'Content-Type': 'application/json',
      token
    };

    const fetchResponse = await fetch(`${apiConfig.hostname}${path}`, {
      method,
      headers,
      credentials: 'include',
      body: data ? JSON.stringify(data) : undefined
    });

    if (!fetchResponse.ok) {
      await this.throwErrorForBadResponse(fetchResponse);
    }

    // parse JSON if it is json, and return plain text if not
    if (LegacyDirectApiRequest.headerIsJson(fetchResponse.headers)) {
      return await fetchResponse.json();
    } else {
      return { text: await fetchResponse.text() };
    }
  }

  static async postFormData(path: string, data: FormData) {
    const token = LegacyDirectApiRequest.getToken();
    const headers = { token };

    const fetchResponse = await fetch(`${apiConfig.hostname}${path}`, {
      method: 'POST',
      // @ts-ignore
      headers,
      credentials: 'include',
      body: data
    });

    if (!fetchResponse.ok) {
      await this.throwErrorForBadResponse(fetchResponse);
    }

    return await fetchResponse.text();
  }

  private static headerIsJson(header: Headers): boolean {
    if (!header.has('content-type')) {
      return false;
    }

    const ctype = header.get('content-type');
    // @ts-ignore
    return /(application|text)\/json/gi.test(ctype);
  }

  private static getToken() {
    return AccountPersistenceLayer.hasJWT()
      ? AccountPersistenceLayer.jwt
      : undefined;
  }

  // NOTE - will always throw if there is no jwt stored
  static async postVerifyJwt(): Promise<any> {
    const response = await this.post(apiConfig.endpoints.verifyJwt, undefined);
    if (parseInt(response.status) >= 400) {
      throw new Error(response);
    }
    return response;
  }

  /**
   * Throws an exception for a response where response.ok === false
   * @param response
   * @private
   */
  private static async throwErrorForBadResponse(response: Response) {
    const responseCode = response.status;
    const responseBodyString = await response.text();
    let exception: any;

    try {
      exception = JSON.parse(responseBodyString);
    } catch (e) {
      // Cannot parse as JSON
      exception = new ApiError(responseCode, responseBodyString);
    }

    throw exception;
  }
}
