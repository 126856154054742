import { filter, takeUntil } from 'rxjs';
import { SecurityReferenceSearchComponent } from './security-reference-search.component';

export class SRSCEmptyInputReactor {
  constructor(private host: SecurityReferenceSearchComponent) {}

  // If the user clears the input, we want to ask Angular to clear the form control
  setUp() {
    this.host.text$
      .pipe(
        filter((text) => !text),
        takeUntil(this.host.componentWillDestroy$)
      )
      .subscribe(() => {
        this.host.notifyAngularOfValueChange(null);
      });
  }
}
