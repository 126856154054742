// This class specialises in sanitizing and parsing dividend action list.

import { flatten } from 'the-great-flattener';
import { DividendEntry } from '../cgt-view.types';
import { normaliseKeysToCamelCase } from './activity-details-parser';
import { FinancialYear } from '../../../utils/financial-year';

export class DividendActionListParser {
  constructor(private rawData: any) {}

  parse(): DividendEntry[] {
    const array = this.peelOffSkin();
    return array.map(this.processIndividualCoopAction).sort((a, b) => {
      return a.exDate > b.exDate ? -1 : 1;
    });
  }

  // -- private methods --

  /**
   * Extract the actual array of different coop actions
   * @private
   */
  private peelOffSkin(): any[] {
    const firstKey = Object.keys(this.rawData[0])[0];
    return this.rawData[0][firstKey];
  }

  private processIndividualCoopAction = (item: any) => {
    const f = normaliseKeysToCamelCase(flatten(item));

    const calculatedGrossFranked = f.rate * f.australianFranked;
    const calculatedImputationCredit = f.imputationCredit * f.rate;

    return {
      activity: f.displayName.trim(),
      exDate: f.exDate,
      itemId: f.coopActionIdCode,
      payDate: f.payDate,
      rate: f.rate,
      security: f.securityCode,
      taxYear: new FinancialYear(f.taxReportDate).getFinancialYear(),
      unitRates: {
        ausGrossFranked: calculatedGrossFranked,
        ausNetFranked: calculatedGrossFranked + calculatedImputationCredit,
        imputationCredit: calculatedImputationCredit
      }
    } as DividendEntry;
  };
}
