import { Component, Input, OnInit } from '@angular/core';
import { LabelValuePair } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-infobox-table',
  templateUrl: './infobox-table.component.html',
  styleUrls: ['./infobox-table.component.scss']
})
export class InfoboxTableComponent implements OnInit {
  // Pass in an array of label-value pairs to display the simple table
  @Input() data!: LabelValuePair[];

  constructor() {}

  ngOnInit(): void {}
}
