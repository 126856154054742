// This class parses a front-end internal Account instance from raw API response.

import { Account } from '../../../account-mgmt/account.model';
import { Role } from '../api-constants/roles/role';
import { Roles } from '../api-constants/roles/roles';
import { jwtDecode } from 'jwt-decode';
import { ITokenPayload } from '../raw-log-in.response';

export class AccountParser {
  static parseAccount(accessToken: string): Account {
    const decoded = jwtDecode<ITokenPayload>(accessToken);

    return {
      accountType: '',
      applications: decoded.applications,
      department: '',
      firstName: '',
      isBlocked: false,
      jobTitle: '',
      lastName: '',
      location: '',
      masterAccountId: decoded.tenantId,
      tenantId: decoded.tenantId,
      masterAccountName: decoded.organisationCode,
      organisationCode: decoded.organisationCode,
      roles: parseRoles(decoded.userRoles),
      email: '',
      id: decoded.userId.toString(),
      isMFAEnabled: decoded.isMFAEnabled,
      isMFAEnforced: decoded.isMFAEnforced
    };
  }
}

function parseRoles(rawRoles: { userRole: Role }[]): Role[] {
  const mappedRoles = rawRoles.map(({ userRole }) => {
    return userRole.roleName;
  });
  return mappedRoles.map((entry) => Roles.byRoleName(entry));
}
