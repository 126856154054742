// This decorator looks for string fragments that look like a plain-formatted date and
// formats it based on the requirements of https://estm.atlassian.net/browse/CGT-60

import { InboundJSONDecorator } from './decorators';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { PRETTY_DATE_FORMAT } from '../utils/date-format';

dayjs.extend(customParseFormat);

export class DateFormatDecorator implements InboundJSONDecorator {

    disabled = false;

    processInboundJSON(data: string): string {
        const datePattern = /[12][90]\d\d-[01]\d-\d\d(?!T)/gim;
        return data.replace(datePattern, date => {
            const dateObj = dayjs(date);
            return dateObj.format(PRETTY_DATE_FORMAT);
        });
    }

}
