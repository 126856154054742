import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AppAutoFocusDirective implements OnInit {

  constructor(private hostElement: ElementRef) { }

  ngOnInit() {
    setTimeout(() => {
      this.hostElement.nativeElement?.focus();
    }, 100);
  }

}
