<div [class]="showActions ? 'action-item-container' : 'action-item-container disable-actions'">
  <p-button
    pRipple
    icon="pi pi-ellipsis-v"
    [id]="'tableMenuPanel_' + id"
    (onClick)="tableMenuPanel.toggle($event)"
    styleClass="p-button-plain p-button-rounded p-button-text p-button-sm"
    [pTooltip]="showActions ? 'Click to view actions' : 'No actions found'"
    tooltipPosition="left"
    [disabled]="!showActions"
    [rounded]="true"
    [text]="true"
    [plain]="true"
  >
    <p-menu [popup]="true" appendTo="body" [model]="tableActions" #tableMenuPanel (onShow)="onSelectRow()"></p-menu>
  </p-button>
</div>
