import { SecurityReference, SecurityReferenceSearchApiClient } from './security-reference-search-api-client.service';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  Observable,
  of,
  raceWith,
  share,
  Subject,
  switchMap,
  takeUntil,
  timer
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SecurityReferenceSearchComponent } from './security-reference-search.component';
import { renderSelectedSecurityText } from './render-selected-security-text';


// This class handles incoming reference data for security reference search component.
// Not for use anywhere else.
class UnpackerReactiveCore {

  ready$ = new BehaviorSubject(false);

  // The host shall react to these:
  overrideReference$: Observable<SecurityReference>;
  clearData$ = new Subject<void>();

  private input$ = new Subject<string>();

  constructor(private apiService: SecurityReferenceSearchApiClient) {
    this.overrideReference$ = this.input$.pipe(
      switchMap((securityId) => this.apiService.unpackSecurityId(securityId)),
      share() // this is important for the raceWith() operator to work
    );
  }

  setUp() {
    // reserved for future use
    this.doSetup()
      .then(() => this.ready$.next(true))
      .catch(() => this.ready$.error('Failed to set up'));
  }

  async doSetup() {
    // Wait for onInput() to be called, then complete when the processing of that input is complete
    // If after 500ms no input is received, complete anyways.

    const timeout$ = timer(500).pipe(
      raceWith(this.input$.pipe(map(() => 1))), // after this, 0 means timeout, 1 means input
      switchMap(item => {
        // If it timed out, emit null straight away.
        // Otherwise, wait until this.overrideReference$ emits.
        if (item === 0) {
          return of(null);
        } else {
          return this.overrideReference$;
        }
      })
    );

    await firstValueFrom(timeout$);
  }

  onInput(securityId: string | null) {
    if (securityId) {
      this.input$.next(securityId);
    } else {
      this.clearData$.next();
    }
  }

}

export class IncomingSecurityUnpacker {

  constructor(private host: SecurityReferenceSearchComponent,
              private apiClient: SecurityReferenceSearchApiClient) {
  }

  private unpacker = new UnpackerReactiveCore(this.apiClient);

  async setUp() {
    this.setupIncomingReferenceReaction();
    this.unpacker.setUp();
    await firstValueFrom(this.unpacker.ready$.pipe(filter((ready) => ready)));
  }

  writeValue(id: string) {
    // On receipt of a new value
    this.unpacker.onInput(id);
  }

  private setupIncomingReferenceReaction() {
    this.unpacker.overrideReference$.pipe(
      takeUntil(this.host.componentWillDestroy$),
      catchError(e => this.host.handleError(e))
    ).subscribe((reference) => {
      this.useIncomingReference(reference);
    });

    this.unpacker.clearData$.pipe(takeUntil(this.host.componentWillDestroy$)).subscribe(() => {
      this.host.text$.next('');
    });
  }

  useIncomingReference(reference: SecurityReference) {
    // Same as onSelect, but without the onChange
    this.host.text$.next(renderSelectedSecurityText(reference));
  }

}
