// This class sanitizes and parses activity details response for the front end's consumption.

import { flatten } from 'the-great-flattener';
import { camelCase, isArray } from 'lodash-es';
import { ApiError } from '../../../utils/api-error';

// These two are in the raw API return's ['sourceDocuments'][n]['fileType'] fields
// It signals whether the given document is a source or a ruling
const FILE_TYPE_SOURCE = '1';
const FILE_TYPE_RULINGS = '0';

export class CgtViewActivityDetailParser {
  private result: any;

  constructor(private rawData: any) {}

  parse(): any {
    this.result = flatten(this.rawData);
    this.checkForErrors();
    this.result = normaliseKeysToCamelCase(this.result);
    this.checkSourceDocuments();
    this.separateSourceDocumentAndRulingDocuments();
    return this.result;
  }

  private checkForErrors() {
    if (this.result.activity === 'No such Activity. Please specify spec' || this.result.activity === 'No Combinations found please recheck') {
      throw new ApiError(404, 'Activity Not Found');
    }
  }

  //region Source Document Handling Functions

  /**
   * When there more than 1 source document, it wont be flattened out into the object.
   * Decide based on key straight forward
   * @private
   */

  private checkSourceDocuments() {
    const sourceDocuments = this.rawData.sourceDocuments || [];
    if (sourceDocuments && Array.isArray(sourceDocuments) && sourceDocuments.length > 1) {
      this.result = {
        ...this.result,
        sourceDocuments
      };
    } else {
      this.checkSourceDocumentList();
    }
  }

  /**
   * When there is only 1 source document, it will be flattened out into the object.
   * This method will intelligently reconstruct the source document in such cases.
   * @private
   */
  private checkSourceDocumentList() {
    if (this.shouldReconstructSourceDocumentArray()) {
      this.result = {
        ...this.result,
        sourceDocuments: this.getReconstructedSourceDocumentArray()
      };
    }
  }

  private shouldReconstructSourceDocumentArray() {
    return this.detailsObjHasAllSourceDocumentKeys() && !this.result.hasOwnProperty('sourceDocuments');
  }

  private getReconstructedSourceDocumentArray() {
    const { source, title, fileType } = this.result;
    return [
      {
        source,
        title,
        fileType
      }
    ];
  }

  private detailsObjHasAllSourceDocumentKeys() {
    const keys = ['source', 'title', 'fileType'];
    return keys.every((k) => this.result.hasOwnProperty(k));
  }

  private getExtn = (title: string) => {
    const extn = title?.split('.').pop();
    console.log(`${title} -> ${extn}`);
    return extn?.toUpperCase();
  };

  private separateSourceDocumentAndRulingDocuments() {
    if (isArray(this.result.sourceDocuments)) {
      const oldSourceDocumentArray = this.result.sourceDocuments as any[];
      const atoRulingsTitle = this.result.atoRulingsDocuments;

      this.result.sourceDocuments = oldSourceDocumentArray
        .filter((d) => d.fileType === FILE_TYPE_SOURCE)
        .map((d) => ({ ...d, icon: this.getExtn(d.title) }));
      this.result.atoRulingsDocuments = oldSourceDocumentArray
        .filter((d) => d.fileType === FILE_TYPE_RULINGS)
        .map((d) => ({
          icon: this.getExtn(d.title),
          title: atoRulingsTitle || d.title,
          source: d.source
        }));
    }
  }

  //endregion
}

/**
 * Scans the keys of data and convert anything that isn't camel case to camel case.
 * Does not change the data.
 * Does not change the original object; returns a new object.
 * @private
 */
export function normaliseKeysToCamelCase(data: any) {
  const result = { ...data };
  const keys = Object.keys(data);

  for (const originalKey of keys) {
    const camelCaseKey = camelCase(originalKey.trim());
    if (originalKey !== camelCaseKey) {
      result[camelCaseKey] = result[originalKey];
      delete result[originalKey];
    }
  }

  return result;
}
