import { DateFormatDecorator } from './date-format-decorator';
import { InvalidDateInterceptor } from './invalid-date-interceptor';
import { OutboundJSONDecorator } from './decorators';

const inboundDecorators = [
    new DateFormatDecorator(),
    new InvalidDateInterceptor()
];

const outboundDecorators: OutboundJSONDecorator[] = [
    // empty for now
];

export function runThroughInboundDecorators(jsonString: string): string {

    let result = jsonString;

    for (const decorator of inboundDecorators) {
        if (!decorator.disabled) {
            result = decorator.processInboundJSON(result)
        }
    }

    return result;

}

export function runThroughOutboundDecorators(jsonString: string): string {

    let result = jsonString;

    for (const decorator of outboundDecorators) {
        if (!decorator.disabled) {
            result = decorator.processOutboundJSON(jsonString);
        }
    }

    return result;

}
