import { ControlValueAccessor } from '@angular/forms';

type OnChangeCallback<IOType> = (arg: IOType | null) => any;
type OnTouchedCallback = () => any;

// Support superclass to separate out the ControlValueAccessor implementation
// Do not use anywhere else.
export abstract class ControlValueAccessorImpl<IOType>
  implements ControlValueAccessor
{
  isDisabled = false;

  notifyAngularOfValueChange: OnChangeCallback<IOType> = () => {};
  notifyAngularOfTouchEvent: OnTouchedCallback = () => {};

  registerOnChange(fn: OnChangeCallback<IOType>): void {
    this.notifyAngularOfValueChange = fn;
  }

  registerOnTouched(fn: OnTouchedCallback): void {
    this.notifyAngularOfTouchEvent = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: IOType): void {
    this.handleIncomingValue(obj);
  }

  abstract handleIncomingValue(value: IOType): void;
}
