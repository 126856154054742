/**
 * These are just some exception classes used by auth-provider.
 */

export class InvalidCredentialsError extends Error {}

// /**
//  * When the email provided by the user does not exist in the database
//  */
// export class EmailDoesNotExistError extends Error {}

/**
 * @Note: Message to be sent in this error will be displayed on top of the MFA form.
 */
export class MFARequiredError extends Error {}

/**
 * When the user must change the password.
 */
export class PWChangeRequiredError extends Error {}
/**
 * When the user enter invlaid MFA like 7 digits
 */
export class MFAValidationError extends Error {}
