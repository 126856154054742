// Handles the responsibility of post-processing raw request.

import { CGTViewAccordionData, CorporateAction, CorporateActionsData } from '../../cgt-view.types';
import { CoopActionTabPostProcessor } from './coop-action-tab-post-processor';
import { CompanyHistoryPostProcessor } from './company-history-post-processor';
import { ApportionmentPostProcessor } from './apportionment-post-processor';

export class CgtViewAccordionDataParser {

    constructor(private rawData: any) {
    }

    processAccordionData(): CGTViewAccordionData {
        try {
            return {
                companyDetails: new CompanyHistoryPostProcessor(this.rawData).assembleCompanyDetails(this.rawData.aggregatedDataForCompanyDetails, this.rawData.aggregatedDataForCompanyHistoryDetails),
                apportionmentEntries: new ApportionmentPostProcessor(this.rawData).assembleApportionmentEntries(),
                corporateActionData: this.assembleCoopActionData(this.rawData.aggregatedDataForCoopActionData)
            };
        } catch (e) {
            console.error(e);
            throw new Error(`Cannot parse return data`);
        }
    }

    private assembleCoopActionData(aggregatedDataForCoopActionData: any): CorporateActionsData {
        return {
            securityCode: this.getSecurityCode(),
            securityName: this.getSecurityName(),
            corporateActions: this.parseCorporateActions(aggregatedDataForCoopActionData)
        };
    }

    private parseCorporateActions(rawValue: any): CorporateAction[] {
        // delegate out
        return new CoopActionTabPostProcessor(rawValue).parse();
    }

    /**
     * Gets the main security name
     * @private
     */
    private getSecurityName() {
        return this.rawData.aggregatedDataForApportionment[0].securityName;
    }

    /**
     * Gets the main security code
     * @private
     */
    private getSecurityCode() {
        return this.rawData.aggregatedDataForApportionment[0].securityCode;
    }

}
