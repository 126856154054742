// All the roles currently known.
// This file is the single source of truth for roles.
// You shouldn't be creating any instances of Role in any other file.

import { Role } from './role';

export const SiteAdminRole: Role = {
  roleName: 'SiteAdmin',
  displayName: 'Site Admin',
  apiInteger: 1,
  id: 1
};

export const AdministratorRole: Role = {
  roleName: 'Administrator',
  displayName: 'Administrator',
  apiInteger: 2,
  id: 2
};

export const CompanyManagerRole: Role = {
  roleName: 'CompanyManager',
  displayName: 'Company Manager',
  apiInteger: 3,
  id: 3
};

export const GroupManagerRole: Role = {
  roleName: 'GroupManager',
  displayName: 'Group Manager',
  apiInteger: 4,
  id: 4
};

export const AdvisorRole: Role = {
  roleName: 'Advisor',
  displayName: 'Advisor',
  apiInteger: 5,
  id: 5
};

export const ConsultantRole: Role = {
  roleName: 'Consultant',
  displayName: 'Consultant',
  apiInteger: 6,
  id: 6
};

export const ClientRole: Role = {
  roleName: 'Client',
  displayName: 'Client',
  apiInteger: 7,
  id: 7
};

export const BackofficeAdminRole: Role = {
  roleName: 'BackofficeAdmin',
  displayName: 'Backoffice Admin',
  apiInteger: 8,
  id: 8
};

export const BackofficeRole: Role = {
  roleName: 'Backoffice',
  displayName: 'Backoffice',
  apiInteger: 9,
  id: 9
};

export const eSupportRole: Role = {
  roleName: 'eSupport',
  displayName: 'eSupport',
  apiInteger: 10,
  id: 10
};
