import { isArray, isString } from 'lodash-es';
import { MessageService } from 'primeng/api';
import { ZodError } from 'zod';
import { fromZodError } from 'zod-validation-error';

/**
 * Given an arbitrary error object, attempt to extract a human-friendly string to describe the error.
 * Will return "Unknown error" if all else fails.
 * @param e The error object.
 */
export function extractErrorText(e: any): string {
  if (!e) {
    return 'Unknown error';
  }

  // Handle Zod errors specifically
  if (e instanceof ZodError) {
    return 'ZodError: An input object appears to be malformed or invalid: ' + fromZodError(e).message;
  }

  // See if there's an "error" object with a message in it
  if (e.error?.message) {
    return parseMessageProperty(e.error.message);
  }

  // See if there's a "message" property
  if (e.message) {
    return parseMessageProperty(e.message);
  }

  // Try to stringify it
  if (e.toString) {
    return e.toString();
  } else {
    return 'Unknown error';
  }
}

export async function sendErrorMessageWith(messageService: MessageService, error: any) {
  await messageService.add({ severity: 'error', summary: 'Error', detail: extractErrorText(error) });
}

/**
 * Given an error message object that may be an array, attempt to parse it into a human-friendly string.
 * @param message The error message.
 */
function parseMessageProperty(message: unknown) {
  if (isArray(message)) {
    return message.join(', ');
  }

  if (isString(message)) {
    return message.split('\n')[0]; // Only show the first line
  }

  return 'Unknown error';
}
