import dayjs from 'dayjs';
import { ALL_VALUE, AU_TAX_YEAR, SERVER_DATE_FORMAT } from 'src/app/app.constants';
import { ITaxYear } from './tax-year.constants';

// Fn to return default dates with whole month date range
export const getDefaultDatesOfMonth = (currentMonth = false) => {
  let endDateOfMonth = new Date();
  let startDateOfMonth = dayjs(endDateOfMonth)
    .month(dayjs(endDateOfMonth).month() - 1)
    .date(dayjs(endDateOfMonth).date() + 1)
    .toDate();

  if (currentMonth) {
    startDateOfMonth = dayjs().startOf('M').toDate();
    endDateOfMonth = dayjs().endOf('M').toDate();
  }

  const fromDate = dayjs(startDateOfMonth).format(SERVER_DATE_FORMAT);
  const toDate = dayjs(endDateOfMonth).format(SERVER_DATE_FORMAT);

  return { fromDate, toDate, dateFilter: [startDateOfMonth, endDateOfMonth] };
};

// Fn to return default dates with 1 year date range
export const getDefaultDatesOfYear = () => {
  const todaysDate = new Date();
  const lastYearsDate = dayjs(todaysDate)
    .year(dayjs(todaysDate).year() - 1)
    .toDate();

  const fromDate = dayjs(lastYearsDate).format(SERVER_DATE_FORMAT);
  const toDate = dayjs(todaysDate).format(SERVER_DATE_FORMAT);

  return { fromDate, toDate, dateFilter: [lastYearsDate, todaysDate] };
};

// Fn to extract dates and process in server format
export const extractDatesFromDateString = (startDateString: string, endDateString: string) => {
  const fromDate = startDateString ? dayjs(startDateString).toDate() : dayjs().toDate();
  const toDate = endDateString ? dayjs(endDateString).toDate() : fromDate;

  return { fromDate, toDate, dateFilter: [fromDate, toDate] };
};

// Fn to extract dates and process in server format
export const extractDates = (reportDateFilter: Date[] | null) => {
  const hasBothDates = reportDateFilter?.filter((date) => date !== null).length === 2;

  const fromDateString = reportDateFilter?.[0];
  const toDateString = reportDateFilter?.[1];

  const fromDate = fromDateString ? dayjs(fromDateString).format(SERVER_DATE_FORMAT) : '';
  const toDate = toDateString ? dayjs(toDateString).format(SERVER_DATE_FORMAT) : fromDate;

  return { fromDate, toDate, hasBothDates };
};

// Fn to update dates based on FY
export const extractDatesFromFy = ({ startYr, endYr, value }: ITaxYear, isAllFY = true) => {
  let startDate = dayjs().date(AU_TAX_YEAR.START.DATE).month(AU_TAX_YEAR.START.MONTH).year(startYr);
  let endDate = dayjs().date(AU_TAX_YEAR.END.DATE).month(AU_TAX_YEAR.END.MONTH).year(endYr);

  if (value === ALL_VALUE && !isAllFY) {
    startDate = dayjs().year(startYr).startOf('year');
    endDate = dayjs();
  }

  const fromDate = startDate.format(SERVER_DATE_FORMAT);
  const toDate = endDate.format(SERVER_DATE_FORMAT);

  const dateFilter = [startDate.toDate(), endDate.toDate()];

  return { fromDate, toDate, dateFilter };
};

// Fn to get tax year range based on provided date
export const getTaxYearDates = (dateString: string | null) => {
  const dateValue = dateString ? dayjs(dateString) : dayjs();
  const currYear = dateValue.year();
  const positionMonth = dateValue.month();
  const isCurrentFYMonth = positionMonth > AU_TAX_YEAR.END.MONTH;

  let fromDate = dateValue;
  if (!isCurrentFYMonth) {
    fromDate = fromDate.set('year', currYear - 1);
  }

  fromDate.set('month', AU_TAX_YEAR.START.MONTH).set('date', AU_TAX_YEAR.START.DATE);

  const toDate = dayjs(fromDate).add(1, 'year').set('month', AU_TAX_YEAR.END.MONTH).set('date', AU_TAX_YEAR.END.DATE);

  return { fromDate, toDate, fromDateString: fromDate.format(SERVER_DATE_FORMAT), toDateString: toDate.format(SERVER_DATE_FORMAT) };
};
