// The "roles" class provides some utility functions for finding roles.
// You may be looking for ./role-instances.ts

import {
  AdministratorRole,
  AdvisorRole,
  BackofficeAdminRole,
  BackofficeRole,
  ClientRole,
  CompanyManagerRole,
  ConsultantRole,
  eSupportRole,
  GroupManagerRole,
  SiteAdminRole
} from './role-instances';
import { Role } from './role';

export class Roles {
  static list: Role[] = [
    eSupportRole,
    SiteAdminRole,
    AdministratorRole,
    CompanyManagerRole,
    BackofficeAdminRole,
    BackofficeRole,
    AdvisorRole
    // GroupManagerRole,
    // ConsultantRole,
    // ClientRole,
  ];

  /**
   * Finds a particular role by its roleName
   */
  static byRoleName(name: string): Role {
    return Roles.findRole((role) => role.roleName === name);
  }

  /**
   * Finds a particular role by its display name
   */
  static byDisplayName(displayName: string): Role {
    return Roles.findRole((role) => role.displayName === displayName);
  }

  /**
   * Finds a particular role by its api integer
   */
  static byApiInteger(apiInteger: number): Role {
    return Roles.findRole((role) => role.apiInteger === apiInteger);
  }

  /**
   * Similar to running Array.prototype.find on Roles.list but throws an error if not found
   * @private
   */
  private static findRole(predicate: (arg0: Role, index: number) => boolean): Role {
    let result = Roles.list.find(predicate) as Role | undefined;

    if (!result) {
      throw new Error('Cannot find the particular role');
    }

    return result;
  }
}
