import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';

/**
 Input
 - item = Record of table for which actions is being generated
 - menuItems = Array of MenuItems, in case of fixed menu options
 - getTableActions = Function to get Array of MenuItems, when visibility and disabled features are handled at menu-item level
 - uniqueId = custom string to identify row, in case of 2 tables are present in same page with same given data set
 */

@Component({
  selector: 'table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.css']
})
export class TableActionsComponent<T> implements OnInit {
  @Input({ required: true }) item!: T;
  @Input({ required: false }) menuItems: MenuItem[] = [];
  @Input({ required: false }) getTableActions: Function = () => {};
  @Input({ required: false }) uniqueId: string = '';

  @Output() onSelect = new EventEmitter<T>();

  tableActions: MenuItem[] = [];
  showActions = false;

  id!: string | number;
  selectedRow!: Record<any, any>;
  selectedOption: any;

  constructor() {}

  ngOnInit(): void {
    if (this.menuItems?.length) {
      // For fixed set of table menu actions
      this.tableActions = [...this.menuItems];
    } else if (this.getTableActions) {
      // For item based table menu actions
      this.tableActions = this.getTableActions(this.item);
    }
    this.showActions = this.tableActions.some((item) => item.visible ?? true);
    const currRowId = (this.item as any)?.id || '';
    this.id = this.uniqueId ? `${this.uniqueId}_${currRowId}` : currRowId;
  }

  onSelectRow = () => {
    this.onSelect.emit(this.item);
  };
}
