import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  providers: [ConfirmationService]
})
export class DeleteConfirmationModalComponent implements OnInit {
  @Input() deleteMsg: string = 'Are you sure you want to delete selected record?';
  @Input({ required: false }) additionalMsg: string = '';

  @Input() onDelete: Function = () => {};
  @Input() onCancel: Function = () => {};

  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit() {
    this.onClickDelete();
  }

  onClickCancel = () => {
    this.onCancel();
  };

  onClickDelete = () => {
    this.confirmationService.confirm({
      key :'4a118568-d659-4091-ab5b-15c31222ca86',
      accept: async () => {
        await this.onDelete();
      },
      reject: async () => {
        await this.onClickCancel();
      }
    });
  };
}
