import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css']
})
export class SearchInputComponent implements OnInit, OnChanges {
  @Input({ required: true }) value: string = '';
  @Input({ required: false }) placeholder: string = 'Search';
  @Input({ required: true }) onSearch!: Function;

  searchText: string = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSearch();
  }

  ngOnInit(): void {
    this.updateSearch();
  }

  private updateSearch = () => {
    this.searchText = this.value;
  };

  onSearchText = () => {
    this.onSearch(this.searchText);
  };

  onClearSearch = () => {
    this.searchText = '';
    this.onSearch('');
  };
}
