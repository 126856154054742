// Delegate for parsing coop actions

import { CorporateAction } from '../../cgt-view.types';
import dayjs from 'dayjs';
import { PRETTY_DATE_FORMAT } from '../../../../utils/date-format';
import { RawValue } from './coop-action-tab-post-processor.types';

export class CoopActionTabPostProcessor {
  constructor(private rawValues: RawValue[]) {}

  parse(): CorporateAction[] {
    return this.rawValues.map(CoopActionTabPostProcessor.parseOneCorporateAction).sort((a, b) => {
      return dayjs(b.date, PRETTY_DATE_FORMAT).valueOf() - dayjs(a.date, PRETTY_DATE_FORMAT).valueOf();
    });
  }

  // -- private methods --

  private static parseOneCorporateAction(rawValue: RawValue): CorporateAction {
    return {
      ...rawValue,
      date: rawValue.executionDate,
      activity: CoopActionTabPostProcessor.getActivityTypeByRawValue(rawValue),
      description: rawValue.description ?? 'MISSING',
      activityId: rawValue.coopActionIdCode
    };
  }

  private static getActivityTypeByRawValue(rawValue: RawValue): string {
    if (rawValue.isBuyback) return rawValue.buybackDisplayName;
    return rawValue.displayTypeName;
  }
}
