import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  // template: ` <object [data]="safeUrl" type="application/pdf" width="100%" height="500"></object> `
  template: `<iframe [src]="safeUrl" frameborder="0" width="100%" height="100%"></iframe> `
})
export class PdfViewerComponent {
  @Input({ required: true }) url!: string;

  safeUrl!: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // console.log(this.url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // console.log(this.safeUrl);
  }
}
