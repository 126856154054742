// API Method Collection for CGT Dashboard

import {
  CGTViewAccordionData,
  DividendEntry,
  EstablishmentRef,
  PricesDataModel,
  SecurityIdUnpackedInfo
} from './cgt-view.types';
import { ApiFetch } from '../../utils/api-fetch';
import { CgtViewAccordionDataParser } from './postprocessors/accordion-data/accordion-data-parser';
import { CgtViewActivityDetailParser } from './postprocessors/activity-details-parser';
import { DividendActionListParser } from './postprocessors/dividend-action-list-parser';
import { PricesDataPostProcessor } from './postprocessors/prices-data-post-processor';

export class CgtViewCollection {

    async getCompanyAutofillSuggestions(input: string, all:boolean): Promise<EstablishmentRef[]> {
        const query = new URLSearchParams({
          code: input,
          all: (all || false).toString(),
        });
        const res = await ApiFetch.post('/api/coopActionTab/companyCodeAutofill?' + query, null);
        return res as EstablishmentRef[];
    }

    async getSecurityListByCompanyId(companyId: string, isUserSecurity:boolean): Promise<EstablishmentRef[]> {
        const query = new URLSearchParams({
          id: companyId,
          isUserSecurity: (isUserSecurity || false).toString(),
        });
        const res = await ApiFetch.post('/api/coopActionTab/securityCodeAutofill?' + query, null);
        return res as EstablishmentRef[];
    }

    async unpackSecurityId(securityId: string): Promise<SecurityIdUnpackedInfo> {
        const query = new URLSearchParams({securityId});
        return await ApiFetch.post('/api/coopActionTab/unpackSecurityId?' + query, null);
    }

    async loadDashboardAccordionData(companyId: string, securityId: string, financialYear: string, reportType: string): Promise<CGTViewAccordionData> {

        const query = new URLSearchParams({reportType});
        const body = {
            securityId,
            FY: financialYear.toUpperCase()
        };
        const rawResponse = await ApiFetch.post('/api/coopActionTab/allAggregatedDataBySecurityId?' + query, body);

        return new CgtViewAccordionDataParser(rawResponse).processAccordionData();

    }

    // TODO: add typing
    async loadActivityDetailsData(activityId: string): Promise<any> {
        const query = new URLSearchParams({coopActionIdCode: activityId});
        const rawData = await ApiFetch.post('/api/coopActionTab/getActivityDetails?' + query, null);
        return new CgtViewActivityDetailParser(rawData).parse();
    }

    async getDividendActionList(securityId: string, financialYear: string): Promise<DividendEntry[]> {
        const data = {securityId, FY: financialYear};
        const rawData = await ApiFetch.post('/api/coopActionTab/dividendActionData', data);
        return new DividendActionListParser(rawData).parse();
    }

    async loadAutoModePricesData(securityId: string, dateRange: string): Promise<PricesDataModel> {
        const query = new URLSearchParams({securityId, dateRange});
        const rawResult = await ApiFetch.post('/api/prices/getGraphData?' + query, null);
        return new PricesDataPostProcessor(rawResult).parse();
    }

    async loadManualModePricesData(securityId: string, startDate: Date, endDate: Date): Promise<PricesDataModel> {
        const query = new URLSearchParams({
            securityId,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        });
        const rawResult = await ApiFetch.post('/api/prices/getGraphDataByDateRange?' + query, null);
        return new PricesDataPostProcessor(rawResult).parse();
    }

}
