// Just a custom error type.

export class ApiError extends Error {

    constructor(public errorCode: number, public message: string) {
        super();
    }

    toString(): string {
        return `API Error ${this.errorCode}: ${this.message}`
    }

    /**
     * Throws an exception for a response where response.ok === false
     * @param response
     */
    static async fromFetchResponse(response: Response) {
        let responseCode = response.status;
        let responseString: any;

        try {
            responseString = await this.extractErrorMessage(response);
        } catch (e) {
            responseString = null;
        }

        return new ApiError(responseCode, responseString);

    }

    private static async extractErrorMessage(response: Response) {

        let text = await response.text();

        try {
            text = this.extractJSONMessageField(text);
        } catch (e) {
            // Either it's not a JSON, or it doesn't have a "message" field
            // pass
        }

        return text;

    }

    private static extractJSONMessageField(text: string) {
        const json = JSON.parse(text);
        if (json.message) {
            text = json.message;
        }
        return text;
    }

}
