import { ApiFetch } from '../../utils/api-fetch';

export class ForgotPasswordCollection {

    async requestForgotPasswordEmail(email: string): Promise<void> {
        await ApiFetch.post('/forgotPassword/requestEmail', {email});
    }

    async resetForgottenPassword(token: string, newPassword: string): Promise<void> {
        await ApiFetch.post('/forgotPassword/resetPassword', {
            token,
            password: newPassword
        });
    }

}
