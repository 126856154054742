// NOTE - hostname should not contain a trailing /

import { ApiFetch } from '../../../../api-connector/utils/api-fetch';
import { servers } from '../../../server.config';

export const apiConfig = {
  hostname: 'somewhere',
  endpoints: {
    verifyUserEmail: '/apiLogin/verifyUserEmail',
    verifyUserCredentials: '/apiVerifyUserCredentials',
    verifyJwt: '/auth/verifyJwtTokenIntegrity',
    companySetup: '/api/companySetup',
    users: '/api/users'
  }
};

export function setDefaultUpstreamHost() {
  setUpstreamHost(servers.cloud);
}

setDefaultUpstreamHost();

export function setUpstreamHost(hostname: string) {
  apiConfig.hostname = hostname;
  ApiFetch.apiHostname = hostname;
}
