import { debounceTime, distinctUntilChanged, mergeWith, Observable, share, Subject, switchMap, takeUntil } from 'rxjs';
import { SecurityReference, SecurityReferenceSearchApiClient } from './security-reference-search-api-client.service';
import { catchError } from 'rxjs/operators';
import { SecurityReferenceSearchComponent } from './security-reference-search.component';

// Delegate class to handle suggestions
// This is only intended for use by SecurityReferenceSearchComponent.
export class SRSCSuggestionHandler {

  // Emit this to force suggestion$ to emit. It is useful to fool the PrimeNG component into stopping its loading animation.
  private forceSuggestions$ = new Subject<SecurityReference[]>();

  constructor(private host: SecurityReferenceSearchComponent,
              private apiClient: SecurityReferenceSearchApiClient) {
  }

  suggestions$: Observable<SecurityReference[]> = this.host.text$.pipe(
    distinctUntilChanged(),
    debounceTime(100),
    switchMap(value => this.getSuggestions(value)),
    mergeWith(this.forceSuggestions$),
    catchError(e => this.host.handleError(e)),
    share(),
    takeUntil(this.host.componentWillDestroy$)
  );

  private shouldTriggerSuggestion(text: string) {
    return this.host.suggestionsEnabled && text && text.length > 2 && text.length < 11;
  }

  async getSuggestions(value: string): Promise<SecurityReference[]> {
    if (!this.shouldTriggerSuggestion(value)) return [];
    return await this.apiClient.autofill(value, this.host.includeAllSecurities);
  }

}
