import { Pipe, PipeTransform } from '@angular/core';
import { ISO8601Date } from '../cgt-view/dividend-calculator/-services-/models/iso8601-date.model';
import { renderCADate, renderDate } from '../utilities/formatting-helpers';

@Pipe({
  name: 'appDate'
})
export class AppDatePipe implements PipeTransform {
  transform(value: Date | ISO8601Date): string {
    return value ? renderDate(value) : '';
  }
}

@Pipe({
  name: 'appCADate'
})
export class AppCADatePipe implements PipeTransform {
  transform(value: Date | ISO8601Date): string {
    return value ? renderCADate(value) : '';
  }
}
