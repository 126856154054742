<p-table [resizableColumns]="true" [value]="data" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
  <ng-template pTemplate="body" let-item>
    <tr class="item-row">
      <td class="label-class">{{ item.label }}</td>
      <td *ngIf="item.isLink">
        <a [href]="'//' + item.value" target="_blank" rel="noopener noreferrer">{{ item.value }}</a>
      </td>
      <td *ngIf="!item.isLink">{{ item.value }}</td>
    </tr>
  </ng-template>
</p-table>
