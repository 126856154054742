import { NgModule } from '@angular/core';
import { AppCADatePipe, AppDatePipe } from './app-date.pipe';
import { AppDateTimePipe } from './app-date-time.pipe';
import { StartCasePipe } from '../cgt-view/-services-/start-case.pipe';
import { AppDecimalPrecision6Pipe } from './pipes/decimal-precision-6.pipe';

@NgModule({
  declarations: [AppDatePipe, AppCADatePipe, AppDateTimePipe, StartCasePipe, AppDecimalPrecision6Pipe],
  exports: [AppDatePipe, AppCADatePipe, AppDateTimePipe, StartCasePipe, AppDecimalPrecision6Pipe],
  imports: []
})
export class CustomPipesModule {}
