import { Component, ElementRef } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'blockable-div',
  template: `<div><ng-content></ng-content></div>`
})
export class BlockableDivComponent implements BlockableUI {
  constructor(private el: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
