import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, merge, startWith, distinctUntilChanged, throwError, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthAPIInterface } from './auth-api.interface';
import { Account } from 'src/app/account-mgmt/account.model';
import { IRefreshTokenResponse, ITokenPayload, RawLogInResponse } from './raw-log-in.response';
import { Role } from './api-constants/roles/role';
import { AccountParser } from './distillery/account-parser';
import { ACCESS_TOKEN_KEY, ACCOUNT_KEY, JWT_KEY, REFRESH_TOKEN_KEY } from './auth-provider.constants';
import { IApplication } from './api-constants/applications/application';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthProviderService {
  private accessTokenKey = ACCESS_TOKEN_KEY;
  private refreshTokenKey = REFRESH_TOKEN_KEY;
  private refreshTimeout: any;
  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn = false;
  public account: Account | null = null;

  public logInEvent = new Subject<void>();
  public logOutEvent = new Subject<void>();
  private accountStateChange$: Observable<void> = merge(this.logInEvent, this.logOutEvent);
  private authApi: AuthAPIInterface = new AuthAPIInterface(this, this.http);

  /**
   * Synchronously emit the current log in state.
   */
  public isLoggedIn$: Observable<boolean> = this.isAuthenticatedSubject.pipe(
    map(() => this.isLoggedIn),
    startWith(this.isLoggedIn),
    distinctUntilChanged()
  );

  /**
   * Synchronously emit the current account.
   */
  public account$: Observable<Account | null> = this.accountStateChange$.pipe(
    map(() => this.account),
    startWith(this.account),
    distinctUntilChanged()
  );

  constructor(public router: Router, private http: HttpClient) {
    this.checkAuthStatus().subscribe();
    this.isAuthenticatedSubject.subscribe((data) => {
      this.isLoggedIn = data;
    }); //
  }

  /**
   * Verifies stored jwt/account with the back end.
   * If not verified, set logged in to false and redirect to log in page.
   * This function is called if the web page is opened with token and account stored
   * @private
   */
  private async verifyStoredCredentials(): Promise<void> {
    const result = await AuthAPIInterface.verifyJWT(this.getAccessToken());

    if (!result) {
      // verification failed
      console.log('Session expired. Please log in again.');
      this.logOut();
    }
  }

  /**
   * Validate that a password meets the requirements for this app. Returns true or false.
   * WARNING - this is not to replace back end checks
   * @param password - the proposed password
   */
  passwordMeetsRequirements(password: string): boolean {
    // We'll add more later.
    return password.length >= 8;
  }

  /**
   * Returns if the current user has a particular role or not
   * Will always return false if the user isn't logged in
   */
  verifyUserRole(role: Role): boolean {
    if (!this.isLoggedIn) {
      return false;
    } else {
      // console.log(role , "incomingrole");
      // console.log(this.account , "accRoles");
      return !!this.account?.roles?.find((r) => r.roleName === role.roleName);
    }
  }

  /**  */
  verifyUserApplication(app: IApplication): boolean {
    if (!this.isLoggedIn) {
      return false;
    } else {
      return !!this.account?.applications?.find((r) => r.id === app.id);
    }
  }

  /**
   * Returns if the current user has a mfa enforced but not enabled
   * Will always return false if the user isn't logged in
   */
  isMFAEnforcedButNotEnabled(): boolean {
    if (!this.isLoggedIn) {
      return false;
    } else {
      return this.account!?.isMFAEnforced && !this.account!?.isMFAEnabled;
    }
  }

  //region Auto-generated delegate functions

  async logIn(username: string, password: string, acceptsToS: boolean, mfaToken?: string) {
    // Send login request and obtain access token and refresh token from the server
    const res = await this.authApi.logIn(username, password, acceptsToS, mfaToken);
    const { accessToken, refreshToken } = res;
    this.setToken(accessToken, refreshToken);
  }

  private confirmSuccessLogIn(accessToken: string) {
    // from this stage we can assume log in is successful
    // (or it would have thrown already)
    const account = AccountParser.parseAccount(accessToken);
    // console.log('account', account);
    this.registerSuccessfulLogIn(account);
  }

  refreshToken(): Observable<IRefreshTokenResponse> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.logOut();
      throw new Error('refreshToken not found');
    }
    return this.authApi.refreshToken(refreshToken);
    // Send refresh token request to the server to obtain a new access token
    // ...
  }

  private setToken(token: string, refreshToken: string): void {
    const tokenExpirationTime = this.getTokenExpirationTime(token);
    const now = Date.now();
    const expiresIn = tokenExpirationTime - now;
    localStorage.setItem(this.accessTokenKey, token);
    localStorage.setItem(this.refreshTokenKey, refreshToken);
    // Legacy key remove in next release
    localStorage.removeItem(JWT_KEY);
    localStorage.removeItem(ACCOUNT_KEY);
    this.confirmSuccessLogIn(token);
    this.startTokenRefreshTimer(expiresIn);
    this.isAuthenticatedSubject.next(true);
  }

  private startTokenRefreshTimer(expiresIn: number): void {
    // Clear any existing refresh timer
    const oneandAHalfMinuteInseconds = 1.5 * 60;
    clearTimeout(this.refreshTimeout);
    // Set a new refresh timer
    this.refreshTimeout = setTimeout(() => {
      this.refreshToken().subscribe({
        next: (response) => {
          const accessToken = response.newAccessToken;
          const refreshToken = response.newRefreshToken;
          this.setToken(accessToken, refreshToken);
        },
        error: () => this.logOut()
      }); // refresh 10s before expiry
    }, expiresIn - oneandAHalfMinuteInseconds * 1000);
    //6000000000
  }

  private getTokenExpirationTime(token: string): number {
    // const stringToBeDecoded = token?.split('.')?.[1] || '';
    if (token) {
      // const { exp } = JSON.parse(window.atob(stringToBeDecoded.replace(/-/g, '+').replace(/_/g, '/')));
      const decoded = jwtDecode<ITokenPayload>(token);

      return decoded.exp! * 1000; // Convert to milliseconds
    }
    return 0;
  }

  public getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  private errorHandler(error: HttpErrorResponse) {
    this.logOut();
    return throwError(() => new Error(error.message || 'server error.'));
  }

  checkAuthStatus(): Observable<any> {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const tokenExpirationTime = this.getTokenExpirationTime(accessToken);
      const now = Date.now();
      const expiresIn = tokenExpirationTime - now;
      // this.account = this.loadAccountFromLocalStorage();
      // refresh 1s before expiry
      if (expiresIn > 1000) {
        this.startTokenRefreshTimer(expiresIn);
        this.confirmSuccessLogIn(accessToken);
        this.isAuthenticatedSubject.next(true);
        this.verifyStoredCredentials();
        return of(null); // Return an observable to indicate completion
      } else {
        return this.refreshToken().pipe(
          catchError((err) => {
            this.errorHandler(err);
            return of(null);
          }),
          tap((response) => {
            if (response?.newAccessToken && response?.newRefreshToken) {
              const newAccessToken = response.newAccessToken;
              const newRefreshToken = response.newRefreshToken;
              this.setToken(newAccessToken, newRefreshToken);
              // set account
              this.confirmSuccessLogIn(accessToken);
            } else {
              this.isAuthenticatedSubject.next(false);
            }
          })
        ); // Return an observable to indicate completion}))
      }
    } else {
      return of(null); // Return an observable to indicate completion
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  logOut(includeReturnUrl: boolean = true) {
    // Clear access token and refresh token from local storage
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    clearTimeout(this.refreshTimeout);
    this.isAuthenticatedSubject.next(false);
    this.authApi.logOut(includeReturnUrl);
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return this.authApi.changePassword(oldPassword, newPassword);
  }

  registerSuccessfulLogIn(account: Account) {
    this.account = account;
    this.logInEvent.next();
  }

  registerLogOut(includeReturnUrl: boolean = true) {
    this.isAuthenticatedSubject.next(false);
    this.account = null;
    this.logOutEvent.next();
    this.router.navigate(['/logIn'], {
      queryParams: this.constructLogOutQueryParams(includeReturnUrl)
    });
  }

  private constructLogOutQueryParams(includeReturnUrl: boolean) {
    if (includeReturnUrl && this.router.routerState.snapshot.url) {
      return {
        returnTo: this.router.routerState.snapshot.url
      };
    }
    return {};
  }

  updateAccountDetails(res: any) {
    this.account = { ...this.account, ...res } as Account;
  }

  // private loadAccountFromLocalStorage(): Account | null {
  //   const rawValue: string | null = localStorage.getItem(ACCOUNT_KEY);
  //   if (!rawValue) {
  //     return null;
  //   } else {
  //     const rawAccount = JSON.parse(rawValue) as Account;

  //     // need to load account constants from imports to avoid duplication of role class
  //     rawAccount.roles = rawAccount.roles.map((role) => Roles.byApiInteger(role.apiInteger));

  //     return rawAccount;
  //   }
  // }

  // getUserData(): Observable<any> {
  //   const headers = this
}
