<p-confirmDialog
  header="Delete Confirmation"
  icon="pi pi-trash"
  key="4a118568-d659-4091-ab5b-15c31222ca86"
  acceptButtonStyleClass="p-button-danger"
  [style]="{ width: '30rem' }"
  appendTo="body"
>
  <ng-template pTemplate="message">
    <div style="text-wrap: pretty; margin-left: 1rem">
      {{ deleteMsg }}
      <div *ngIf="additionalMsg" class="mt-3" style="font-size: 14px; color: var(--primary-color)">{{ additionalMsg }}</div>
    </div>
  </ng-template>
</p-confirmDialog>
