// This is the global revolver class
// Containing all packages' endpoints as delegates

import { CgtViewCollection } from './api-collections/cgt-view/cgt-view.collection';
import { UserFeedbackCollection } from './api-collections/user-feedback/user-feedback.collection';
import { MfaCollection } from './api-collections/mfa/mfa.collection';
import {
  ESupportAndCompanySetupCollection
} from './api-collections/e-support-and-company-setup/e-support-and-company-setup.collection';
import { VerifyMfaResponse } from './api-collections/mfa/verify-mfa-token-response.model';
import {
  CGTViewAccordionData,
  DividendEntry,
  EstablishmentRef,
  PricesDataModel,
  SecurityIdUnpackedInfo
} from './api-collections/cgt-view/cgt-view.types';
import { ValidateMFATokenResponse } from './api-collections/mfa/validate-mfa-token-response.model';
import { ActiveUserResponse } from './api-collections/e-support-and-company-setup/active-user-response.model';
import { UserFeedback } from './api-collections/user-feedback/user-feedback.model';
import { DisableMFAResponse } from './api-collections/mfa/disable-mfa-response.model';
import { CreateAccountInput } from './api-collections/e-support-and-company-setup/create-account-input.model';
import { EnableMfaResponse } from './api-collections/mfa/enable-mfa-response.model';
import {
  CustomerOrganisationInfo
} from './api-collections/e-support-and-company-setup/customer-organisation-info.model';
import { ForgotPasswordCollection } from './api-collections/forgot-password/forgot-password.collection';

export class ApiHttpRequest {

    // -- delegate fields --
    private static cgtViewCollection: CgtViewCollection = new CgtViewCollection();
    private static userFeedbackCollection: UserFeedbackCollection = new UserFeedbackCollection();
    private static mfaCollection: MfaCollection = new MfaCollection();
    private static eSupportAndCompanySetupCollection: ESupportAndCompanySetupCollection = new ESupportAndCompanySetupCollection();
    private static forgotPasswordCollection: ForgotPasswordCollection = new ForgotPasswordCollection();

    // -- delegate methods --
    // DO NOT EDIT MANUALLY; use code generator!

    static async addUserFeedback(userId: string, title: string, content: string): Promise<void> {
        return this.userFeedbackCollection.addUserFeedback(userId, title, content);
    }

    static async patchUserFeedback(id: string, body: { [Property in keyof UserFeedback]?: string }): Promise<any> {
        return this.userFeedbackCollection.patchUserFeedback(id, body);
    }

    static async getAllUserFeedbacks(): Promise<UserFeedback[]> {
        return this.userFeedbackCollection.getAllUserFeedbacks();
    }

    static async getUserFeedbackById(id: string): Promise<UserFeedback> {
        return this.userFeedbackCollection.getUserFeedbackById(id);
    }

    static async deleteUserFeedback(id: string): Promise<void> {
        return this.userFeedbackCollection.deleteUserFeedback(id);
    }

    static async createEsupportOrSiteAdminAccount(input: CreateAccountInput): Promise<any> {
        return this.eSupportAndCompanySetupCollection.createEsupportOrSiteAdminAccount(input);
    }

    static async listUsersWithLatestLoginActivities(): Promise<ActiveUserResponse[]> {
        return this.eSupportAndCompanySetupCollection.listUsersWithLatestLoginActivities();
    }

    static async getFullCustomerOrganisationInformation(): Promise<CustomerOrganisationInfo> {
        return this.eSupportAndCompanySetupCollection.getFullCustomerOrganisationInformation();
    }

    static async getCompanyAutofillSuggestions(input: string, all:boolean): Promise<EstablishmentRef[]> {
        return this.cgtViewCollection.getCompanyAutofillSuggestions(input, all);
    }

    static async getSecurityListByCompanyId(companyId: string, isUserSecurity:boolean): Promise<EstablishmentRef[]> {
        return this.cgtViewCollection.getSecurityListByCompanyId(companyId, isUserSecurity);
    }

    static async unpackSecurityId(securityId: string): Promise<SecurityIdUnpackedInfo> {
        return this.cgtViewCollection.unpackSecurityId(securityId);
    }

    static async loadDashboardAccordionData(companyId: string, securityId: string, financialYear: string, reportType: string): Promise<CGTViewAccordionData> {
        return this.cgtViewCollection.loadDashboardAccordionData(companyId, securityId, financialYear, reportType);
    }

    static async loadActivityDetailsData(activityId: string): Promise<any> {
        return this.cgtViewCollection.loadActivityDetailsData(activityId);
    }

    static async getDividendActionList(securityId: string, financialYear: string): Promise<DividendEntry[]> {
        return this.cgtViewCollection.getDividendActionList(securityId, financialYear);
    }

    static async loadAutoModePricesData(securityId: string, dateRange: string): Promise<PricesDataModel> {
        return this.cgtViewCollection.loadAutoModePricesData(securityId, dateRange);
    }

    static async loadManualModePricesData(securityId: string, startDate: Date, endDate: Date): Promise<PricesDataModel> {
        return this.cgtViewCollection.loadManualModePricesData(securityId, startDate, endDate);
    }

    static async enableMFA(): Promise<EnableMfaResponse> {
        return this.mfaCollection.enableMFA();
    }

    static async verifyMFAToken(token: string): Promise<VerifyMfaResponse> {
        return this.mfaCollection.verifyMFAToken(token);
    }

    static async validateMFAToken(token: string): Promise<ValidateMFATokenResponse> {
        return this.mfaCollection.validateMFAToken(token);
    }

    static async disableMFA(): Promise<DisableMFAResponse> {
        return this.mfaCollection.disableMFA();
    }

    static async requestForgotPasswordEmail(email: string): Promise<void> {
        return this.forgotPasswordCollection.requestForgotPasswordEmail(email);
    }

    static async resetForgottenPassword(token: string, newPassword: string): Promise<void> {
        return this.forgotPasswordCollection.resetForgottenPassword(token, newPassword);
    }
}
