import { CreateAccountInput } from './create-account-input.model';
import { ActiveUserRawResponse, ActiveUserResponse } from './active-user-response.model';
import { CustomerOrganisationInfo } from './customer-organisation-info.model';
import { ApiFetch } from 'src/api-connector/utils/api-fetch';

export class ESupportAndCompanySetupCollection {

    async createEsupportOrSiteAdminAccount(input: CreateAccountInput): Promise<any> {
        await ApiFetch.post('/api/eSupportAndCompanySetup', input);
    }

    async listUsersWithLatestLoginActivities(): Promise<ActiveUserResponse[]> {
        const rawResponse = await ApiFetch.get('/api/eSupportAndCompanySetup/listUsersWithLatestLoginActivities') as ActiveUserRawResponse[];
        return rawResponse.map(parseActiveUserRawResponse);
    }

    async getFullCustomerOrganisationInformation(): Promise<CustomerOrganisationInfo> {
        return await ApiFetch.get("/api/customerOrganization/byMasterAccountId");
    }

}

export const parseActiveUserRawResponse = (e: ActiveUserRawResponse) => ({
    ...e,
    userRoles: e.userUserRoleMapping.map(i => i.roleId),
    userApplicationAccess: e.userApplicationAccess.map(i => i.masterEstmApplicationId)
})

