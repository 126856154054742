// CRUD methods for user feedback

import { UserFeedback } from './user-feedback.model';
import { ApiFetch } from '../../utils/api-fetch';

export class UserFeedbackCollection {

    public async addUserFeedback(userId: string, title: string, content: string): Promise<void> {
        await ApiFetch.post("/api/userFeedback/add", {
            userId, title, content
        });
    }

    public async patchUserFeedback(id: string, body: { [Property in keyof UserFeedback]?: string }): Promise<any> {
        return await ApiFetch.patch('/api/userFeedback/' + id, body);
    }

    public async getAllUserFeedbacks(): Promise<UserFeedback[]> {
        return await ApiFetch.get('/api/userFeedback');
    }

    public async getUserFeedbackById(id: string): Promise<UserFeedback> {
        return await ApiFetch.get(`/api/userFeedback/${id}`);
    }

    public async deleteUserFeedback(id: string): Promise<void> {
        await ApiFetch.delete(`/api/userFeedback/${id}`, null);
    }

}

