/**
 * For use in stream filter clauses.
 * @example
 * ```ts
 * const newArr = [1, 2, 3, 4, 5, null].filter(truthyOnly)
 *       ^ Array<number>
 * ```
 */
export const truthyOnly = Boolean as any as Super;
type Super = <T>(value: T | null | undefined | false) => value is T;

export const excludeFalsy = truthyOnly;
export const excludeNull = truthyOnly;
export const excludeUndefined = truthyOnly;
export const pickTruthy = truthyOnly;
