// This class just stores tokens

import { ACCESS_TOKEN_KEY } from 'src/app/-services-/auth-framework/auth-provider.constants';

type Token = string | null;

// should be same as in estm-ui src/app/-services-/auth-framework/jwt/account-persistence-layer.ts
const KEY = ACCESS_TOKEN_KEY;
class BasicTokenPersistenceLayer {
  static getToken(): Token {
    return localStorage.getItem(KEY);
  }

  static setToken(token: Token) {
    if (token) {
      localStorage.setItem(KEY, token);
    } else {
      localStorage.removeItem(KEY);
    }
  }
}

export class TokenPersistenceLayer extends BasicTokenPersistenceLayer {
  private static initialised = false;
  private static cachedToken: Token;

  static getToken(): Token {
    return localStorage.getItem(ACCESS_TOKEN_KEY) || '';
    // if (!this.initialised) this.initialise();
    // return this.cachedToken;
  }

  static setToken(token: Token) {
    if (!this.initialised) this.initialise();
    super.setToken(token);
    this.cachedToken = token;
  }

  private static initialise() {
    this.cachedToken = super.getToken();
    this.initialised = true;
  }
}
