export enum fileTypes {
  txt = 'txt',
  csv = 'csv',
  html = 'html',
  json = 'json',
  pdf = 'pdf',
  xlsx = 'xlsx',
  zip = 'zip',
  tsv = ' tsv'
}

export const fileMimeTypeMapping = {
  [fileTypes.txt]: 'text/plain',
  [fileTypes.csv]: 'text/csv',
  [fileTypes.html]: 'text/html',
  [fileTypes.json]: 'application/json',
  [fileTypes.pdf]: 'application/pdf',
  [fileTypes.xlsx]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [fileTypes.zip]: 'application/x-zip-compressed',
  [fileTypes.tsv]: 'text/tab-separated-values'
};

export const getFileMimeType = (fileName: string = '') => {
  const extn = (fileName?.split('.').pop() || '').toLowerCase() as fileTypes;
  const mimeType = fileMimeTypeMapping[extn];
  if (!mimeType) {
    throw Error('File type not supported');
  }
  return mimeType;
};
