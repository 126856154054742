import { CompanyDetailsData, LabelValuePair } from '../../cgt-view.types';
import { startCase } from 'lodash-es';

export class CompanyHistoryPostProcessor {
  constructor(private rawData: any) {}

  public assembleCompanyDetails(aggregatedDataForCompanyDetails: any, aggregatedDataForCompanyHistoryDetails: any): CompanyDetailsData {
    const rawCompanyDetails = aggregatedDataForCompanyDetails;
    const rawCompanyHistoryDetails = forceCastToArray(aggregatedDataForCompanyHistoryDetails);

    const { companyDetails, registryDetails } = this.generateCompanyAndRegistryDetails(rawCompanyDetails);

    return {
      companyDetails,
      registryDetails,
      companyHistoryData: this.assembleCompanyHistoryData(rawCompanyHistoryDetails)
    };
  }

  private generateCompanyAndRegistryDetails = (rawCompanyDetails: any) => {
    const companyDetails: LabelValuePair[] = [
      { label: 'Company Code', value: rawCompanyDetails.companyCode },
      { label: 'Exchange', value: rawCompanyDetails.companyExchange },
      { label: 'Company Name', value: rawCompanyDetails.companyName },
      { label: 'Company Type', value: rawCompanyDetails.companyType },
      { label: 'Commencement Date', value: rawCompanyDetails.companyListedDate },
      { label: 'Termination Date', value: rawCompanyDetails.companyTerminatedDate },
      { label: 'Secretary', value: rawCompanyDetails.companySecretary },
      { label: 'Address', value: `${rawCompanyDetails.companyAddr1} ${rawCompanyDetails.companyAddr2}` }
    ];
    const registryDetails: LabelValuePair[] = [
      { label: 'Registry', value: rawCompanyDetails.registryName },
      // { label: 'Registry Address', value: '--N/A--' },
      // { label: 'Registry Phone', value: rawCompanyDetails.registryPhone },
      // { label: 'Registry Fax', value: rawCompanyDetails.registryFax },
      { label: 'Registry Website', value: rawCompanyDetails.registryUrl, isLink: true }
    ];

    return { companyDetails, registryDetails };
  };

  // Old processor which directly generated label key value from BE response structure
  // Deprecated as we need custom labels on UI for company and registry details
  private extractCompanyAndRegistryDetails(rawCompanyDetails: any) {
    const companyDetails: LabelValuePair[] = [];
    const registryDetails: LabelValuePair[] = [];

    for (const key in rawCompanyDetails) {
      // @ts-ignore
      const item = { label: startCase(key), value: rawCompanyDetails[key] };
      if (/^registry/gi.test(key)) {
        registryDetails.push(item);
      } else {
        companyDetails.push(item);
      }
    }

    return { companyDetails, registryDetails };
  }

  private assembleCompanyHistoryData(input: any) {
    return input.map((item: { date: any; code: any; name: any }) => ({
      label: item.date,
      value: `${item.code} - ${item.name}`
    }));
  }
}

/**
 * If data is not an array, wrap it in one. Otherwise, returns itself.
 * @param data
 */
function forceCastToArray(data: any): any[] {
  if (Array.isArray(data)) {
    return data;
  } else {
    return [data];
  }
}
