// Post processor for the graph API.

import { DataPoint, PricesDataModel } from '../cgt-view.types';

export class PricesDataPostProcessor {

    constructor(private rawData: RawData) {
    }

    parse(): PricesDataModel {
        return {
            companyName: this.rawData.companyName,
            dataPoints: this.extractDataPoints()
        };
    }

    private extractDataPoints(): DataPoint[] {
        return this.rawData.dataPoints.map(d => ({
            date: d[0],
            closePrice: d[1],
            open: d[2],
            high: d[3],
            low: d[4],
            volume: d[5]
        } as DataPoint));
    }

}

interface RawData {
    companyName: string;
    dataPoints: (string | number)[][];
}
