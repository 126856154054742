import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReadOnlyLabelValueComponent } from './read-only-label-value/read-only-label-value.component';
import { BorderedFieldGroupComponent } from './bordered-field-group/bordered-field-group.component';
import { SecurityReferenceSearchComponent } from './security-reference-search/security-reference-search.component';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { CalendarComponent } from './calender/calender.component';
import { StripContentComponent } from './strip-content/strip-content.component';
import { BlockableDivComponent } from './blockable-div/blockable-div.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

import { FormsModule } from '@angular/forms';
import { NgLetModule } from 'ng-let';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    ReadOnlyLabelValueComponent, //Remove this in later ticket
    BorderedFieldGroupComponent,
    SecurityReferenceSearchComponent,
    CalendarComponent,
    TableActionsComponent,
    StripContentComponent,
    BlockableDivComponent,
    DeleteConfirmationModalComponent,
    SearchInputComponent,
    PdfViewerComponent
  ],
  exports: [
    ReadOnlyLabelValueComponent, //Remove this in later ticket
    BorderedFieldGroupComponent,
    SecurityReferenceSearchComponent,
    CalendarComponent,
    TableActionsComponent,
    StripContentComponent,
    BlockableDivComponent,
    DeleteConfirmationModalComponent,
    SearchInputComponent,
    PdfViewerComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    NgLetModule,
    ButtonModule,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    MenuModule,
    ConfirmDialogModule,
    InputTextModule
  ]
})
export class CustomCommonModule {}
