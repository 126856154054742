import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Calendar as PrimeNGCalendar } from 'primeng/calendar';
import { extractDates, extractDatesFromFy, getDefaultDatesOfYear } from './calender.utilities';
import { ICalenderDropdown, ICalenderEvent } from './calender.types';
import { ITaxYear, getTaxYears } from './tax-year.constants';

/**
 * Component: Calender + FY Dropdown
 * Inputs:
 * label = Text string to display custom floating label
 * isMandatory = Boolean flag to mark important
 * initialise = Boolean flag to initiase the calender component
 * initialValue = Array of Dates or null, to assign component on load or reset
 * dropdown = ICalenderDropdown <maxTaxYears, includeAll, isAllFY> pass value only when to show FY dropdown
 *    maxTaxYears = Number value to generate tax year range
 *    includeAll = To display ALL option in FY list
 *    isAllFY = To evaluate ALL to follow FY pattern
 * Output:
 * onChange = Event called on update of calender component, on initialisation or reset
 */

const defaultDropdownParams = {
  maxTaxYears: 10,
  includeAll: false,
  isAllFY: true
};

@Component({
  selector: 'app-fy-calender',
  templateUrl: './calender.component.html'
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendarInstance') calendarInstance!: PrimeNGCalendar;

  @Input({ required: false }) isMandatory = false;
  @Input({ required: false }) label = 'Date Range';
  @Input({ required: false }) isDisabled = false;

  @Input({ required: false }) initialise = true;
  @Input({ required: false }) initialValue: Date[] | null = null;

  @Input({ required: false }) dropdown: ICalenderDropdown | false = defaultDropdownParams;

  @Output() onChange = new EventEmitter<ICalenderEvent>();

  private fyParams!: ICalenderDropdown;

  financialYears: ITaxYear[] = [];
  selectedFy: ITaxYear | null = null;

  reportDateFilter: Date[] | null = [];

  constructor() {}

  ngOnInit(): void {
    this.fyParams = { ...defaultDropdownParams, ...this.dropdown };
    const { maxTaxYears, includeAll } = this.fyParams;
    this.financialYears = this.dropdown ? getTaxYears(maxTaxYears, includeAll) : [];

    if (this.initialise) {
      this.initialiseCalender();
    }
  }

  private onCalenderChange = (fromDate: string, toDate: string) => {
    this.onChange.emit({ fromDate, toDate });
  };

  private initialiseCalender = () => {
    if (this.initialValue && Array.isArray(this.initialValue)) {
      this.reportDateFilter = [...this.initialValue];
      const { fromDate, toDate } = extractDates(this.reportDateFilter);
      this.onCalenderChange(fromDate, toDate);
    } else {
      const { fromDate, toDate, dateFilter } = getDefaultDatesOfYear();
      this.reportDateFilter = dateFilter;
      this.onCalenderChange(fromDate, toDate);
    }
  };

  onDateFilterChange = () => {
    const { fromDate, toDate, hasBothDates } = extractDates(this.reportDateFilter);
    if (hasBothDates) {
      this.onCalenderChange(fromDate, toDate);
      this.calendarInstance.toggle();
    }
  };

  onFYChange = () => {
    if (this.selectedFy) {
      const { fromDate, toDate, dateFilter } = extractDatesFromFy(this.selectedFy, this.fyParams.isAllFY);
      this.reportDateFilter = dateFilter;
      this.selectedFy = null;
      this.onCalenderChange(fromDate, toDate);
      this.calendarInstance.toggle();
    }
  };

  onReset = (dateValues?: Date[]) => {
    if (dateValues && Array.isArray(dateValues)) {
      this.reportDateFilter = dateValues;
      const { fromDate, toDate } = extractDates(this.reportDateFilter);
      this.onCalenderChange(fromDate, toDate);
    } else {
      this.calendarInstance.clear();
      this.onCalenderChange('', '');
    }
  };
}
