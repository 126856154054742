import { ApportionmentDescription, ApportionmentEntry } from '../../cgt-view.types';
import dayjs from 'dayjs';
import { PRETTY_DATE_FORMAT } from '../../../../utils/date-format';

export class ApportionmentPostProcessor {

    constructor(private rawData: any) {
    }

    public assembleApportionmentEntries(aggregatedDataForApportionment: any[] = this.rawData.aggregatedDataForApportionment) {
        return aggregatedDataForApportionment
            .map((entry: any) => this.processApportionmentEntries(entry));
    }

    private processApportionmentEntries(input: { securityName: string; securityCode: string; apportionment: ApportionmentDescription[] }): ApportionmentEntry {
        const sortedApportionments = input.apportionment.sort(compareApportionmentDates);
        return {
            securityCode: input.securityCode,
            securityName: input.securityName,
            apportionments: sortedApportionments
        };
    }

}

const compareApportionmentDates = (a: ApportionmentDescription, b: ApportionmentDescription) => {
    const aEndDate = dayjs(a.periodEnd, PRETTY_DATE_FORMAT).valueOf();
    const bEndDate = dayjs(b.periodEnd, PRETTY_DATE_FORMAT).valueOf();
    return bEndDate - aEndDate;
};
