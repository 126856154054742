<span class="p-float-label">
  <p-calendar
    [(ngModel)]="reportDateFilter"
    selectionMode="range"
    dateFormat="dd/mm/yy"
    (ngModelChange)="onDateFilterChange()"
    [disabled]="isDisabled"
    placeholder="DD/MM/YYYY - DD/MM/YYYY"
    [showOnFocus]="false"
    [showIcon]="true"
    #calendarInstance
  >
    <ng-template pTemplate="header" *ngIf="!!dropdown">
      <div>
        <span class="p-float-label">
          <p-dropdown
            [options]="financialYears"
            optionLabel="label"
            [(ngModel)]="selectedFy"
            (ngModelChange)="onFYChange()"
            [style]="{ width: '100%' }"
          ></p-dropdown>
          <label> Financial Year </label>
        </span>
      </div>
    </ng-template>
  </p-calendar>
  <label>
    <span *ngIf="isMandatory" style="color: red; font-size: larger">*</span>
    {{ label }}
  </label>
</span>
