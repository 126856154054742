import { ALL_VALUE } from 'src/app/app.constants';
import { extractDatesFromFy } from './calender.utilities';

export interface ITaxYear {
  label: string;
  value: string;
  startYr: number;
  endYr: number;
  startDate?: string | Date;
  endDate?: string | Date;
}

export const getTaxYears = (maxYears = 6, hasAllOption = false, includeDates = false) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  // currentMonth has value 0-11
  const currentYear = currentDate.getFullYear();
  const currentFY = currentMonth < 6 ? currentYear : currentYear + 1;

  const taxYears = [];
  // Add all option
  if (hasAllOption) {
    taxYears.push({
      label: 'All',
      value: ALL_VALUE,
      startYr: 1900,
      endYr: currentFY
    });
  }

  // Generate tax years
  for (let index = 0; index < maxYears; index++) {
    const thisYr = currentFY - index;
    const lastYr = thisYr - 1;
    const txYrObj = {
      label: `${lastYr}/${thisYr.toString().slice(-2)}`,
      value: `FY${thisYr.toString().slice(-2)}`,
      startYr: lastYr,
      endYr: thisYr
    } as ITaxYear;

    if (includeDates) {
      const { fromDate, toDate } = extractDatesFromFy({ startYr: txYrObj.startYr, endYr: txYrObj.endYr, value: '' } as ITaxYear);
      txYrObj.startDate = fromDate;
      txYrObj.endDate = toDate;
    }

    taxYears.push(txYrObj);
  }

  return taxYears;
};
