import { Component, Input } from '@angular/core';

/**
 * A cleaner and more developer-friendly wrapper for PrimeNG's float labels.
 * See: https://primefaces.org/primeng/floatlabel
 *
 * Icons are supported; just pass in the class names of the icons,
 * and the position of the icon "left" or "right" only (defaults to left)
 *
 * Pass in an optional "fluid" attribute to enable or disable the p-fluid class on the container.
 * This defaults to true.
 *
 * @example
 * <app-float-label label="My Label">
 *   <input type="text" pInputText [formControl]="..." />
 * </app-float-label>
 */
@Component({
  selector: 'app-float-label',
  templateUrl: './float-label.component.html',
  styleUrls: ['./float-label.component.css']
})
export class FloatLabelComponent {
  @Input() label!: string;
  @Input() icon: string | null = null;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() fluid: boolean = true;
  @Input({ required: false }) isMandatory: boolean = false;

  shouldHaveIcon() {
    return this.icon != null;
  }

  effectiveIconPosition() {
    if (!this.shouldHaveIcon()) return null;
    return this.iconPosition == 'right' ? 'right' : 'left';
  }
}
