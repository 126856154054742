// This is just a collection of utility functions to fire requests at the api.

import { TokenPersistenceLayer } from '../token-persistence-layer';
import { ApiError } from './api-error';
import { runThroughInboundDecorators, runThroughOutboundDecorators } from '../json-decorators/decorators-controller';

export class ApiFetch {
  // This must be set by whoever uses this API collection.
  // Otherwise, it defaults to the current default.
  static apiHostname = 'http://13.238.218.131:4001';

  //region Public method wrappers...
  static async post(path: string, data: any): Promise<any> {
    return await ApiFetch.fetchAndGetJSON('POST', path, data);
  }

  static async get(path: string): Promise<any> {
    return await ApiFetch.fetchAndGetJSON('GET', path, undefined);
  }

  static async put(path: string, data: any): Promise<any> {
    return await ApiFetch.fetchAndGetJSON('PUT', path, data);
  }

  static async delete(path: string, data: any): Promise<any> {
    return await ApiFetch.fetchAndGetJSON('DELETE', path, data);
  }

  static async patch(path: string, data: any): Promise<any> {
    return await ApiFetch.fetchAndGetJSON('PATCH', path, data);
  }

  //endregion

  /**
   * Sends a request to the API.
   * @param method - HTTP method to use
   * @param path - relative path to push to
   * @param data - any body data
   * @private
   */
  private static async fetchAndGetJSON(method: string, path: string, data: any) {
    const token = ApiFetch.getToken();

    const headers: any = {
      'Content-Type': 'application/json',
      token
    };

    const fetchResponse = await fetch(`${this.apiHostname}${path}`, {
      method,
      credentials: 'include',
      headers,
      body: data ? runThroughOutboundDecorators(JSON.stringify(data)) : undefined
    });

    if (!fetchResponse.ok) {
      throw await ApiError.fromFetchResponse(fetchResponse);
    }

    // parse JSON if it is json, and return plain text if not
    if (ApiFetch.headerIsJson(fetchResponse.headers)) {
      const resultString = runThroughInboundDecorators(await fetchResponse.text());
      return JSON.parse(resultString);
    } else {
      return { text: await fetchResponse.text() };
    }
  }

  static async postFormData(path: string, data: FormData) {
    const token = ApiFetch.getToken() ?? 'null';
    const headers = { token };

    const fetchResponse = await fetch(`${this.apiHostname}${path}`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: data
    });

    if (!fetchResponse.ok) {
      throw await ApiError.fromFetchResponse(fetchResponse);
    }

    return await fetchResponse.text();
  }

  //region Private methods...

  private static headerIsJson(header: Headers): boolean {
    if (!header.has('content-type')) {
      return false;
    }

    const ctype = header.get('content-type');
    return /(application|text)\/json/gi.test(ctype!);
  }

  private static getToken() {
    return TokenPersistenceLayer.getToken();
  }

  //endregion
}
