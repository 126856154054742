import { EnableMfaResponse } from './enable-mfa-response.model';
import { VerifyMfaResponse } from './verify-mfa-token-response.model';
import { ApiError } from '../../utils/api-error';
import { ValidateMFATokenResponse } from './validate-mfa-token-response.model';
import { DisableMFAResponse } from './disable-mfa-response.model';
import { LegacyDirectApiRequest } from 'src/app/-services-/auth-framework/api-connect/legacy-direct-api-request';

export class MfaCollection {
  async enableMFA(): Promise<EnableMfaResponse> {
    return await LegacyDirectApiRequest.post('/mfa/enableMfa', null);
  }

  /**
   * Verify an MFA token to finish enabling MFA
   */
  async verifyMFAToken(token: string): Promise<VerifyMfaResponse> {
    const response = (await LegacyDirectApiRequest.post('/mfa/verify', {
      mfaToken: token
    })) as VerifyMfaResponse;

    if (response.verified) {
      return response;
    } else {
      throw new ApiError(406, 'The MFA token is invalid.');
    }
  }

  /**
   * Validate MFA token. This is used for sign in where MFA is required.
   * @param token
   */
  async validateMFAToken(token: string): Promise<ValidateMFATokenResponse> {
    return await LegacyDirectApiRequest.post('/mfa/validate', {
      mfaToken: token
    });
  }

  async disableMFA(): Promise<DisableMFAResponse> {
    const result = await LegacyDirectApiRequest.post('/mfa/disableMfa', null);
    console.log(result);
    return result;
  }
}
