import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'strip-content',
  templateUrl: './strip-content.component.html',
  styleUrls: ['./strip-content.component.css']
})
export class StripContentComponent implements OnInit {
  @Input({ required: true }) str: string = '';

  @Input({ required: false }) customClass?: string = '';
  @Input({ required: false }) maxLength!: number;
  @Input({ required: false }) maxLine!: number;

  strClass = '';
  text = '';

  constructor() {}

  ngOnInit(): void {
    this.text = this.str;
    let styleClass = '';
    if (this.maxLine) {
      const moreThan2LinesClass = `max-line-str-${this.maxLine}`;
      styleClass = `max-line-str ${moreThan2LinesClass}`;
    } else if (this.maxLength) {
      this.text = this.str?.slice(0, this.maxLength).concat('...');
      styleClass = 'max-length-str';
    } else {
      styleClass = 'default-strip-str';
    }
    this.strClass = `${styleClass} ${this.customClass}`;
  }
}
