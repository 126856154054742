export class FinancialYear {

    date: Date;

    constructor(dateString: string) {
        this.date = new Date(dateString);
    }

    getFinancialYear() {
        if (this.date.getMonth() < 6) {
            return this.date.getFullYear();
        } else {
            return this.date.getFullYear() + 1;
        }
    }

    toString(): string {
        return 'FY' + this.getFinancialYear().toString();
    }

}