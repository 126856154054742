// This decorator replaces all 0000-00-00 with INVALID_DATE

import { InboundJSONDecorator } from './decorators';

export class InvalidDateInterceptor implements InboundJSONDecorator {

    disabled = false;

    processInboundJSON(data: string): string {
        return data.replace(/0000-00-00/gi, '');
    }

}
