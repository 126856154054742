import { LegacyDirectApiRequest } from './api-connect/legacy-direct-api-request';
import { InvalidCredentialsError, MFARequiredError, MFAValidationError, PWChangeRequiredError } from './auth-errors';
import { SwalToastSuccess } from '../../common/util/swal-mixins/swal-toast-success';
import { AuthProviderService } from './auth-provider.service';
import { IRefreshTokenResponse, ITokenPayload, RawLogInResponse } from './raw-log-in.response';
import { HttpClient } from '@angular/common/http';
import { ApiHttpRequest } from '../../../api-connector/api-http-request';
import { firstValueFrom, Observable } from 'rxjs';
import { ValidateMFATokenResponse } from 'src/api-connector/api-collections/mfa/validate-mfa-token-response.model';
import { ACCESS_TOKEN_KEY } from './auth-provider.constants';
import { jwtDecode } from 'jwt-decode';

/**
 * Delegate class for AuthProviderService,
 * handling all the API interactions
 */
export class AuthAPIInterface {
  constructor(private host: AuthProviderService, private http: HttpClient) {}

  static async verifyJWT(jwt: string | null): Promise<boolean> {
    try {
      if (!jwt) return false;
      await LegacyDirectApiRequest.postVerifyJwt();
      return true;
    } catch (e) {
      return false;
    }
  }

  async logIn(username: string, password: string, acceptsToS: boolean, mfaToken?: string): Promise<{ accessToken: string; refreshToken: string }> {
    if (mfaToken) {
      return await this.logInWithMFAToken(mfaToken, acceptsToS);
    } else {
      return await this.logInWithoutMFAToken(username, password, acceptsToS);
    }
  }

  refreshToken(token: string): Observable<IRefreshTokenResponse> {
    return this.http.post<IRefreshTokenResponse>('refresh-token', {
      refreshToken: token
    });
  }

  logOut(includeReturnUrl: boolean) {
    // redirect to log in page and notify user
    SwalToastSuccess.fire('You have been logged out.');
    this.host.registerLogOut(includeReturnUrl);
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    // NOTE- JWT check is not needed as /api routes are checked for token in server

    // send the request to change password
    await LegacyDirectApiRequest.patch('/api/users/updatePassword', {
      userPassword: newPassword,
      oldPassword
    });
  }

  private async logInWithMFAToken(mfaToken: string, acceptsToS: boolean): Promise<ValidateMFATokenResponse> {
    try {
      const res = await ApiHttpRequest.validateMFAToken(mfaToken);
      if (!res.accessToken && !res.isUserCredentialsVerified) {
        throw new InvalidCredentialsError('MFA code is incorrect or expired.');
      }
      if (res.name === 'ValidationError') {
        throw new MFAValidationError('MFA code must be 6 digits.');
      }
      const decoded = jwtDecode<ITokenPayload>(res.accessToken);
      if (decoded.isMFAVerified) {
        return res;
      } else {
        throw new Error('Something is not right with MFA.');
      }
    } catch (e) {
      if ((e as { name: 'ValidationError' }).name === 'ValidationError') {
        throw new MFAValidationError('MFA code must be 6 digits.');
      }
      throw e;
    }
  }

  private async logInWithoutMFAToken(username: string, password: string, acceptsToS: boolean): Promise<RawLogInResponse> {
    let response: RawLogInResponse;

    try {
      response = await firstValueFrom(
        this.http.post<RawLogInResponse>('apiVerifyUserCredentials', {
          userEmail: username,
          userPassword: password,
          acceptsToS
        })
      );
    } catch (e: any) {
      if (e?.error?.message) {
        throw new InvalidCredentialsError(e.error.message);
      }
      throw new InvalidCredentialsError();
    }

    const decoded = jwtDecode<ITokenPayload>(response.accessToken);

    // if PW change is required, the user should not be set to be logged in.
    if (decoded.isFirstLogin) {
      localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
      throw new PWChangeRequiredError();
    }

    // If MFA is enabled, it needs to be verified first.
    if (decoded.isMFAEnabled && !decoded.isMFAVerified) {
      localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
      throw new MFARequiredError('Please enter the one-time token generated by your TOTP app:');
    }

    return response;
  }

  // private confirmSuccessLogIn(response: RawLogInResponse) {
  //   // from this stage we can assume log in is successful
  //   // (or it would have thrown already)
  //   const account = AccountParser.parseAccount(response);
  //   this.host.registerSuccessfulLogIn(account);
  // }
}

export interface UserEmailExistsResponse {
  userEmailExists: boolean;
}
