import { Component, Input, OnInit } from '@angular/core';
import { renderFancyDateTimeWithDelta } from '../../utilities/formatting-helpers';

@Component({
  selector: 'app-fancy-datetime',
  templateUrl: './fancy-datetime.component.html',
  styleUrls: ['./fancy-datetime.component.css']
})
export class FancyDatetimeComponent implements OnInit {

  @Input() date!: Date | string;
  @Input() fallback: string = 'Invalid Date';

  constructor() { }

  ngOnInit(): void {
  }


  render() {
    const date = this.date;
    return renderFancyDateTimeWithDelta(date, this.fallback);
  }

}
