<app-notice-card
  *ngIf='errorText'
  icon='pi pi-exclamation-triangle'
  [content]='errorText'
></app-notice-card>

<app-notice-card
  *ngIf='!errorText && isLoading'
  icon='pi pi-spinner pi-spin'
  content='Loading...'
></app-notice-card>
