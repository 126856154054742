import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { SecurityReference, SecurityReferenceSearchApiClient } from './security-reference-search-api-client.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorImpl } from '../../utilities/cva-assist';
import { BehaviorSubject, combineLatestWith, filter, NEVER, shareReplay, startWith, Subject } from 'rxjs';
import { extractErrorText } from '../../utilities/extract-error-text';
import { SRSCSuggestionHandler } from './srsc-suggestion.handler';
import { IncomingSecurityUnpacker } from './incoming-unpacker';
import { SRSCEmptyInputReactor } from './empty-input.reactor';
import { renderSelectedSecurityText } from './render-selected-security-text';
import { map } from 'rxjs/operators';
import { pickTruthy } from '../../utilities/truthy-only';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-security-reference-search',
  templateUrl: './security-reference-search.component.html',
  styleUrls: ['./security-reference-search.component.scss'],
  providers: [
    SecurityReferenceSearchApiClient,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SecurityReferenceSearchComponent)
    }
  ]
})
export class SecurityReferenceSearchComponent
  extends ControlValueAccessorImpl<SecurityReference | string>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  // DOM-linked states
  @Input() includeAllSecurities = false;
  @Input() label: string = 'Security';

  // Additional support
  @Input() isMandatory: boolean = false;
  @Input() getFullObj: boolean = false;
  @Input({ required: false }) autofocusField: boolean = false;

  // Internal states
  text$ = new BehaviorSubject<string>(''); // This is two-way-bound to the input field
  errorText: string | null = null;
  suggestionsEnabled = false; // We don't want to trigger suggestions until the component is ready.

  // Tracks whether the input element is currently in focus
  focused$ = new BehaviorSubject<boolean>(false);

  // Primitive destroy ref
  componentWillDestroy$ = new Subject<void>();

  // Delegates
  public suggestionHandler = new SRSCSuggestionHandler(this, this.apiClient); // public because and only because it is used in the template
  private unpacker = new IncomingSecurityUnpacker(this, this.apiClient);
  private emptyInputReactor = new SRSCEmptyInputReactor(this);

  // Derived states

  tooltipEnabled$ = this.text$.pipe(
    map((t) => t.length > 0 && t.length < 3),
    combineLatestWith(this.focused$),
    map(([tooShort, focused]) => tooShort && focused)
  );

  tooltipEverTriggered$ = this.tooltipEnabled$.pipe(startWith(false), filter(pickTruthy), shareReplay(1));

  // Methods

  constructor(private apiClient: SecurityReferenceSearchApiClient) {
    super();
  }

  ngOnDestroy(): void {
    this.componentWillDestroy$.next();
    this.componentWillDestroy$.complete();
    this.text$.complete();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.emptyInputReactor.setUp();
      await this.unpacker.setUp();
      this.suggestionsEnabled = true;
    } catch (e: any) {
      this.handleError(e);
    }
  }

  onSelect({ value: selectedReference }: AutoCompleteSelectEvent) {
    this.text$.next(renderSelectedSecurityText(selectedReference));
    this.notifyAngularOfValueChange(this.getFullObj ? selectedReference : selectedReference.id);
  }

  handleIncomingValue(value: string) {
    this.unpacker.writeValue(value);
  }

  // Simple type assist function
  castToSecurityReference(input: any): SecurityReference {
    return input;
  }

  // Error handling function for use in RxJS pipes
  handleError(e: any) {
    this.errorText = extractErrorText(e);
    return NEVER;
  }
}
