<span class="p-float-label">
  <p-autoComplete
    [suggestions]="$any(suggestionHandler.suggestions$ | async)"
    [ngModel]="text$ | async"
    (ngModelChange)="text$.next($event)"
    (onSelect)="onSelect($event)"
    [disabled]="isDisabled"
    (onFocus)="focused$.next(true)"
    (onBlur)="focused$.next(false)"
    pAutoFocus
    [autofocus]="autofocusField"
  >
    <ng-template let-suggestionRaw pTemplate="item">
      <span *ngIf="castToSecurityReference(suggestionRaw) as suggestion">
        <strong>{{ suggestion.code }}.{{ suggestion.exchangeCode }}</strong> - {{ suggestion.name }}
      </span>
    </ng-template>
  </p-autoComplete>
  <label>
    <span *ngIf="isMandatory" style="color: red; font-size: larger">* </span>
    {{ label }}
  </label>
</span>

<p class="p-error" *ngIf="errorText">{{ errorText }}</p>

<ng-container *ngLet="tooltipEnabled$ | async as tooltipEnabled">
  <div class="tooltip" *ngIf="tooltipEverTriggered$ | async" [class.tooltip-in]="tooltipEnabled" [class.tooltip-out]="!tooltipEnabled">
    Keep typing to search...
  </div>
</ng-container>
