import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EstablishmentRef } from '../../../api-connector/api-collections/cgt-view/cgt-view.types';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class SecurityReferenceSearchApiClient {

  constructor(private http: HttpClient) { }

  /**
   * Autofill security reference
   * @param code - security code
   * @param all - whether to "include all"
   */
  async autofill(code: string, all: boolean = false): Promise<SecurityReference[]> {
    const obs$ = this.http.get<SecurityReference[]>(`api/securityReferences/auto-fill`, {
      params: {code, all}
    });
    return await lastValueFrom(obs$);
  }

  /**
   * Given a security ID, return the whole security reference.
   * @param securityId - security id
   */
  async unpackSecurityId(securityId: string): Promise<SecurityReference> {
    const obs$ = this.http.get<SecurityReference>(`api/securityReferences/unpack`, {
      params: {id: securityId}
    });
    return await lastValueFrom(obs$);
  }

}

export interface SecurityReference extends EstablishmentRef {
  // Reserved for future use
}
