import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthProviderService } from '../../-services-/auth-framework/auth-provider.service';
import { MessageService } from 'primeng/api';
import { debounceTime, fromEvent, NEVER, Observable, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appIdleTimeout]'
})
export class IdleTimeoutDirective implements OnInit, OnDestroy {
  constructor(private authProviderService: AuthProviderService, private zone: NgZone, private msg: MessageService) {}

  private readonly IDLE_TIMEOUT_MILLISECONDS = 30 * 60 * 1000; // 30 minutes

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      const debouncedMouseMove$ = fromEvent(document, 'mousemove').pipe(
        startWith(void 0),
        map(() => void 0),
        debounceTime(this.IDLE_TIMEOUT_MILLISECONDS)
      ) as Observable<void>;

      const switched$ = this.authProviderService.isLoggedIn$.pipe(
        switchMap((isLoggedIn) => (isLoggedIn ? debouncedMouseMove$ : NEVER)),
        takeUntil(this.destroy$)
      );

      switched$.subscribe(() => {
        this.kickUser();
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async kickUser() {
    await this.zone.run(async () => {
      await this.authProviderService.logOut();
      this.msg.add(toastConfig);
    });
  }
}

const toastConfig = {
  severity: 'error',
  summary: 'Inactive for 30 minutes',
  detail: 'For your security, you have been logged out due to inactivity.',
  sticky: true
};
