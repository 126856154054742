// This class manages one request for flattenation
export class Flattener {
  constructor(subject) {
    this.subject = subject;
    this.result = {};
    this.usedKeys = new Set();
  }
  flatten() {
    this.flattenLayer(this.subject);
    return this.result;
  }
  // -- private methods --
  /**
   * Flattens one layer of the object, for recursive calls.
   * Adds results to this.result.
   * Assumes layer is an object type
   * @param layer
   * @private
   */
  flattenLayer(layer) {
    for (const key in layer) {
      if (!layer.hasOwnProperty(key)) continue;
      // @ts-ignore
      const value = layer[key];
      if (typeof value === 'object') {
        if (Array.isArray(value) && value.length > 1) {
          this.flattenArray(key, value);
        } else {
          this.flattenLayer(value);
        }
      } else {
        this.addOneValue(key, value);
      }
    }
  }
  /**
   * Adds one value to the result
   * Assumes value is not another object
   * @param key
   * @param value
   * @private
   */
  addOneValue(key, value) {
    const keyToUse = this.getSafeToUseKey(key);
    this.usedKeys.add(keyToUse);
    // @ts-ignore
    this.result[keyToUse] = value;
  }
  /**
   * Checks "key", "keyCopy", "keyCopyCopy" etc. and return the first one that's not in this.usedKeys
   * @param key
   * @private
   */
  getSafeToUseKey(key) {
    if (!this.usedKeys.has(key)) {
      return key;
    } else {
      return this.getSafeToUseKey(key + 'Copy');
    }
  }
  /**
   * Add an array to the key with every single item flattened.
   * @param key - key of the array
   * @param array - the array itself
   * @private
   */
  flattenArray(key, array) {
    this.addOneValue(key, array.map(i => {
      return new Flattener(i).flatten();
    }));
  }
}