import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomPipesModule } from './custom-pipes.module';
import { CustomDirectivesModule } from './custom-directives.module';
import { CustomCommonModule } from './custom-common.module';

import { FancyDatetimeComponent } from './fancy-datetime/fancy-datetime.component';
import { FloatLabelComponent } from './float-label/float-label.component';
import { ErrorAndLoadingNoticeCardsComponent } from './error-and-loading-notice-cards/error-and-loading-notice-cards.component';
import { FileUploadComponent } from './util/file-upload/file-upload.component';
import { InfoboxTableComponent } from './util/infobox-table/infobox-table.component';
import { CardSkeletonComponent } from './util/card-skeleton/card-skeleton.component';
import { NoticeCardComponent } from './util/notice-card/notice-card.component';

import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { FieldsetModule } from 'primeng/fieldset';

@NgModule({
  declarations: [
    // Check and move actually multi-used components to common module
    FancyDatetimeComponent,
    FloatLabelComponent,
    ErrorAndLoadingNoticeCardsComponent,
    FileUploadComponent,
    InfoboxTableComponent,
    CardSkeletonComponent,
    NoticeCardComponent
  ],
  exports: [
    FancyDatetimeComponent,
    FloatLabelComponent,
    ErrorAndLoadingNoticeCardsComponent,
    FileUploadComponent,
    InfoboxTableComponent,
    CardSkeletonComponent,
    NoticeCardComponent,
    // Independent Modules as per feature
    CustomPipesModule,
    CustomDirectivesModule,
    CustomCommonModule
  ],
  imports: [
    CommonModule,
    TableModule,
    FileUploadModule,
    FieldsetModule,
    SkeletonModule,
    // Independent Modules as per feature
    CustomPipesModule,
    CustomDirectivesModule,
    CustomCommonModule
  ]
})
export class CommonSharedModule {}
